'use client';

import dynamic from 'next/dynamic';
import { Suspense } from 'react';

// Componente statice
import Hero from '@/components/hero/hero';
import EntrepreneurFeatures from '@/components/services/meseriasi';
import RecentJobs from '@/components/home/RecentJobs';
import FeaturedWorkers from '@/components/home/FeaturedWorkers';
import NewHero from '@/components/hero/NewHero';

// Încărcare dinamică pentru componentele care nu sunt esențiale
const ServicesSlider = dynamic(() => import('@/components/services/slider'), {
  loading: () => (
    <div className="animate-pulse p-4">
      <div className="h-64 bg-gray-200 rounded"></div>
    </div>
  ),
  ssr: false
});

const RecentArticles = dynamic(() => import('@/components/blog/RecentArticles'), {
  loading: () => (
    <div className="animate-pulse p-4">
      <div className="h-8 bg-gray-200 rounded w-1/4 mb-6"></div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="h-48 bg-gray-200 rounded"></div>
        <div className="h-48 bg-gray-200 rounded"></div>
      </div>
    </div>
  ),
  ssr: false
});

// Încărcare dinamică pentru secțiunile care pot fi încărcate ulterior
const DynamicHowItWorks = dynamic(() => import('@/components/services/HowItWorks'), {
  ssr: false
});

const DynamicTestimonials = dynamic(() => import('@/components/testimonials/TestimonialsSection'), {
  ssr: false
});

export default function HomeClient() {
  return (
    <main className="relative">
      {/* <Hero /> */}
      <NewHero />
      <Suspense fallback={null}>
        <FeaturedWorkers />
      <RecentJobs />
        <DynamicTestimonials />
        <DynamicHowItWorks />
        <ServicesSlider />
      <EntrepreneurFeatures />
      
        <RecentArticles />
      </Suspense>
    </main>
  );
} 