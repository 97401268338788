// Categorii de locații
const LOCATION_TYPES = {
  CITY: 'city',
  SECTOR: 'sector',
  DISTRICT: 'district',
  SUBURB: 'suburb'
};

// Structura organizată a locațiilor
export const locations = {
  cities: [
    "bucuresti",
    "cluj-napoca", 
    "timisoara",
    "iasi",
    "constanta",
    "craiova",
    "brasov",
    "galati",
    "ploiesti",
    "oradea",
    "braila",
    "arad",
    "pitesti",
    "sibiu",
    "bolintin-vale",
    "bacau",
    "targu-mures",
    "baia-mare",
    "buzau",
    "botosani",
    "satu-mare",
    "suceava",
    "piatra-neamt",
    "drobeta-turnu-severin",
    "targu-jiu",
    "targoviste",
    "focsani",
    "bistrita",
    "resita",
    "tulcea",
    "calarasi",
    "giurgiu",
    "alba-iulia",
    "deva",
    "hunedoara",
    "zalau",
    "sfantu-gheorghe",
    "alexandria",
    "slobozia",
    "vaslui",
    "ovidiu",
    "medias",
    "roman",
    "turda",
    "ramnicu-valcea",
    "slatina",
    "miercurea-ciuc",
    "siret",
    "darabani",
    // Orașe turistice importante
    "sinaia",
    "busteni",
    "azuga",
    "predeal",
    "poiana-brasov",
    "baile-felix",
    "baile-herculane",
    "vatra-dornei",
    "mangalia",
    "eforie-nord",
    "mamaia",
    "costinesti",
    "navodari"
  ],
  
  bucharest: {
    sectors: [
      "Sector 1",
      "Sector 2",
      "Sector 3",
      "Sector 4",
      "Sector 5",
      "Sector 6"
    ],
    districts: [
      "Berceni",
      "Pantelimon",
      "Colentina",
      "Drumul Taberei",
      "Militari",
      "Rahova",
      "Giurgiului",
      "Titan",
      "Balta Albă",
      "Dorobanți",
      "Aviației",
      "Primăverii",
      "Tei",
      "Vitan",
      "Tineretului",
      "Pipera"
    ]
  },
  
  suburbs: [
    "Ștefăneștii de Jos",
    "Voluntari",
    "Chiajna",
    "Bragadiru",
    "Otopeni",
    "Buftea",
    "Mogoșoaia",
    "Corbeanca",
    "Domnești",
    "Popești-Leordeni",
    "Jilava",
    "Afumați",
    "Clinceni",
    "Cernica",
    "Snagov"
  ]
};

// Lista completă a tuturor locațiilor
export const allLocations = [
  ...locations.cities,
  ...locations.bucharest.sectors,
  ...locations.bucharest.districts,
  ...locations.suburbs,
  "Ilfov"
];

// Funcții helper pentru manipularea locațiilor
export const getLocationType = (location) => {
  if (locations.cities.includes(location)) return LOCATION_TYPES.CITY;
  if (locations.bucharest.sectors.includes(location)) return LOCATION_TYPES.SECTOR;
  if (locations.bucharest.districts.includes(location)) return LOCATION_TYPES.DISTRICT;
  if (locations.suburbs.includes(location)) return LOCATION_TYPES.SUBURB;
  return null;
};

export const isInBucharest = (location) => {
  return locations.bucharest.sectors.includes(location) || 
         locations.bucharest.districts.includes(location);
};

export const isSuburb = (location) => {
  return locations.suburbs.includes(location);
};

export const getCitySlug = (location) => {
  if (!location) return '';
  
  // Mapping pentru diacritice și caractere speciale
  const diacriticsMap = {
    'ă': 'a',
    'â': 'a',
    'î': 'i',
    'ș': 's',
    'ş': 's',
    'ț': 't',
    'ţ': 't',
    'Ă': 'A',
    'Â': 'A',
    'Î': 'I',
    'Ș': 'S',
    'Ş': 'S',
    'Ț': 'T',
    'Ţ': 'T'
  };

  return location
    .toLowerCase()
    .split('')
    .map(char => diacriticsMap[char] || char)
    .join('')
    .replace(/[^a-z0-9]+/g, '-') // Înlocuiește orice non-alfanumeric cu -
    .replace(/^-+|-+$/g, '') // Elimină - de la început și sfârșit
    .replace(/-+/g, '-'); // Înlocuiește multiple - consecutive cu unul singur
};

export const getDisplayLocation = (location) => {
  if (location === 'Ilfov') return location;
  if (isInBucharest(location)) return `${location}, București`;
  if (isSuburb(location)) return `${location}, Ilfov`;
  return location;
};

// Orașele principale pentru homepage cu descrieri SEO
export const mainCities = [
  {
    name: "București",
    description: "Găsește cei mai buni meseriași și profesioniști în București pentru renovări, reparații și amenajări. Servicii de calitate, prețuri competitive și expertiză locală garantată."
  },
  {
    name: "Cluj-Napoca",
    description: "Servicii profesionale de renovare și reparații în Cluj-Napoca. Meseriași verificați, experiență dovedită și satisfacție garantată pentru toate lucrările tale."
  },
  {
    name: "Timișoara",
    description: "Conectăm clienții din Timișoara cu cei mai buni meseriași locali. Servicii complete de amenajări, instalații și reparații la standarde înalte."
  },
  {
    name: "Iași",
    description: "Profesioniști verificați pentru orice tip de lucrare în Iași. De la renovări complete la reparații punctuale, găsești Meserias specializați pentru nevoile tale."
  },
  {
    name: "Constanța",
    description: "Servicii complete de amenajări și reparații în Constanța. Echipe profesioniste, materiale de calitate și rezultate garantate pentru locuința ta."
  },
  {
    name: "Brașov",
    description: "Găsește rapid meseriași profesioniști în Brașov pentru orice tip de lucrare. Servicii complete de amenajări și reparații la cele mai înalte standarde."
  }
];

export const cities = [
  "Alba Iulia",
  "Arad",
  "Bacău",
  "Baia Mare",
  "Bistrița",
  "Botoșani",
  "Brăila",
  "Brașov",
  "București",
  "Buzău",
  "Călărași",
  "Cluj-Napoca",
  "Constanța",
  "Craiova",
  "Deva",
  "Drobeta-Turnu Severin",
  "Focșani",
  "Galați",
  "Giurgiu",
  "Iași",
  "Miercurea Ciuc",
  "Oradea",
  "Piatra Neamț",
  "Pitești",
  "Ploiești",
  "Râmnicu Vâlcea",
  "Reșița",
  "Satu Mare",
  "Sfântu Gheorghe",
  "Sibiu",
  "Slatina",
  "Slobozia",
  "Suceava",
  "Târgoviște",
  "Târgu Jiu",
  "Târgu Mureș",
  "Timișoara",
  "Tulcea",
  "Vaslui",
  "Zalău"
];