// TikTok Events
export const TIKTOK_EVENTS = {
  VIEW_CONTENT: 'ViewContent',
  ADD_TO_CART: 'AddToCart',
  CLICK_BUTTON: 'ClickButton',
  COMPLETE_PAYMENT: 'CompletePayment',
  SIGN_UP: 'SignUp',
  LOGIN: 'Login',
  COMPLETE_REGISTRATION: 'CompleteRegistration'
};

// Hash data using SHA-256
const hashData = async (data) => {
  if (!data) return null;
  const encoder = new TextEncoder();
  const encodedData = encoder.encode(data);
  const hashBuffer = await crypto.subtle.digest('SHA-256', encodedData);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
};

// Identify user for TikTok
export const identifyTikTokUser = async (user) => {
  if (!window.ttq || !user) return;

  try {
    const hashedEmail = await hashData(user.email);
    const hashedPhone = await hashData(user.phone_number);
    const hashedId = await hashData(user.id);

    window.ttq.identify({
      email: hashedEmail,
      phone_number: hashedPhone,
      external_id: hashedId
    });
  } catch (error) {
    console.error('Error identifying user for TikTok:', error);
  }
};

// Track TikTok event
export const trackTikTokEvent = (eventName, params = {}) => {
  if (!window.ttq) return;

  try {
    switch (eventName) {
      case TIKTOK_EVENTS.VIEW_CONTENT:
      case TIKTOK_EVENTS.ADD_TO_CART:
      case TIKTOK_EVENTS.CLICK_BUTTON:
      case TIKTOK_EVENTS.COMPLETE_PAYMENT:
        window.ttq.track(eventName, {
          contents: [{
            content_id: params.content_id || '',
            content_type: params.content_type || 'product',
            content_name: params.content_name || '',
            quantity: params.quantity,
            brand: params.brand
          }],
          value: params.value || 0,
          currency: params.currency || 'RON',
          status: params.status
        });
        break;

      // Simple events without content details
      case TIKTOK_EVENTS.SIGN_UP:
      case TIKTOK_EVENTS.LOGIN:
      case TIKTOK_EVENTS.COMPLETE_REGISTRATION:
        window.ttq.track(eventName, params);
        break;

      default:
        console.warn('Unknown TikTok event:', eventName);
    }
  } catch (error) {
    console.error('Error tracking TikTok event:', error);
  }
};

// Initialize TikTok Pixel
export const initTikTokPixel = () => {
  if (typeof window !== 'undefined') {
    !function (w, d, t) {
      w.TiktokAnalyticsObject=t;
      var ttq=w[t]=w[t]||[];
      ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"];
      ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};
      for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
      ttq.instance=function(t){
        for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);
        return e
      };
      ttq.load=function(e,n){
        var r="https://analytics.tiktok.com/i18n/pixel/events.js";
        ttq._i=ttq._i||{};
        ttq._i[e]=[];
        ttq._i[e]._u=r;
        ttq._t=ttq._t||{};
        ttq._t[e]=+new Date;
        ttq._o=ttq._o||{};
        ttq._o[e]=n||{};
        var i=document.createElement("script");
        i.type="text/javascript";
        i.async=true;
        i.src=r+"?sdkid="+e+"&lib="+t;
        var s=document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(i,s);
      };
      
      // Load your pixel
      ttq.load('CU72P73C77UDT6BP5LJG');
      ttq.page();
    }(window, document, 'ttq');
  }
}; 