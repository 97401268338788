'use client';

import { useEffect, useState } from 'react';
import { supabase } from '@/lib/supabase';
import { MapPin, Star, ArrowRight, CheckCircle, Clock, Shield } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import { generateWorkerSlug } from '@/utils/slugs';
import { motion } from 'framer-motion';

function WorkerSkeleton() {
  return (
    <div className="flex gap-6 px-4 overflow-x-auto pb-4">
      {[1, 2, 3].map((i) => (
        <div key={i} className="bg-white rounded-2xl shadow-lg animate-pulse min-w-[300px]">
          <div className="p-4">
            <div className="w-16 h-16 bg-gray-200 rounded-xl mb-4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/2 mb-2"></div>
            <div className="h-3 bg-gray-200 rounded w-3/4 mb-2"></div>
            <div className="h-3 bg-gray-200 rounded w-2/3"></div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default function FeaturedWorkers() {
  const [workers, setWorkers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWorkers = async () => {
      try {
        setLoading(true);
        
        const { data: workersData, error: workersError } = await supabase
          .from('profiles')
          .select(`
            *,
            reviews!reviews_worker_id_fkey (
              rating
            )
          `)
          .eq('role', 'worker')
          .ilike('avatar_url', '%supabase.co/storage/v1/object/public/profileImages%')
          .limit(15);

        if (workersError) throw workersError;

        const processedWorkers = workersData
          .filter(worker => 
            worker.avatar_url?.includes('/profileImages/') && 
            worker.name && 
            worker.trade
          )
          .map(worker => {
            const reviews = worker.reviews || [];
            const totalRating = reviews.reduce((sum, review) => sum + (review.rating || 0), 0);
            const reviewCount = reviews.length;
            const averageRating = reviewCount > 0 ? totalRating / reviewCount : 0;

            return {
              ...worker,
              reviewCount,
              averageRating: averageRating || 0,
              hasBio: !!worker.bio
            };
          });

        const sortedWorkers = processedWorkers
          .sort((a, b) => {
            if (a.is_verified && !b.is_verified) return -1;
            if (!a.is_verified && b.is_verified) return 1;
            if (a.hasBio && !b.hasBio) return -1;
            if (!a.hasBio && b.hasBio) return 1;
            if (a.reviewCount > 0 && b.reviewCount === 0) return -1;
            if (a.reviewCount === 0 && b.reviewCount > 0) return 1;
            return b.averageRating - a.averageRating;
          })
          .slice(0, 8); // Showing more workers for horizontal scroll

        setWorkers(sortedWorkers);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchWorkers();
  }, []);

  if (loading) return <WorkerSkeleton />;

  return (
    <section className="py-16 bg-gradient-to-b from-gray-50 to-white overflow-hidden">
      <div className="container mx-auto">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12 px-4"
        >
          <span className="text-orange-500 font-semibold text-sm uppercase tracking-wider mb-4 block">
            Profesioniști Verificați
          </span>
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Meseriași Disponibili<span className="text-orange-500">.</span>
          </h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Găsește rapid un meșter de încredere pentru orice tip de lucrare
          </p>
        </motion.div>

        {/* Horizontal Scroll Container */}
        <div className="relative">
          {/* Gradient Fade Effect */}
          <div className="absolute left-0 top-0 bottom-0 w-8 bg-gradient-to-r from-white to-transparent z-10 pointer-events-none" />
          <div className="absolute right-0 top-0 bottom-0 w-8 bg-gradient-to-l from-white to-transparent z-10 pointer-events-none" />
          
          {/* Scrollable Container */}
          <div className="overflow-x-auto scrollbar-hide">
            <div className="flex gap-6 px-4 pb-4 min-w-min">
              {workers.map((worker, index) => (
                <motion.div
                  key={worker.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="group flex-shrink-0 w-[300px]"
                >
                  <Link 
                    href={`/meseriasi/${generateWorkerSlug(worker.name, worker.trade, worker.address, worker.id)}`}
                    className="block bg-white rounded-2xl overflow-hidden transition-all duration-300
                             hover:shadow-[0_8px_30px_rgb(0,0,0,0.06)] hover:-translate-y-1"
                  >
                    <div className="p-6">
                      {/* Header with Avatar and Verification */}
                      <div className="flex items-center gap-4 mb-4">
                        <div className="relative">
                          <div className="w-16 h-16 rounded-xl border-2 border-white shadow-md overflow-hidden">
                            <Image
                              src={worker.avatar_url || '/images/default-avatar.png'}
                              alt={worker.name || 'Default Avatar'}
                              width={64}
                              height={64}
                              className="object-cover w-full h-full"
                            />
                          </div>
                          {worker.is_verified && (
                            <div className="absolute -bottom-1 -right-1 bg-white rounded-full p-0.5 shadow">
                              <CheckCircle className="w-4 h-4 text-orange-500" />
                            </div>
                          )}
                        </div>
                        <div>
                          <h3 className="text-lg font-bold text-gray-900 group-hover:text-orange-500 transition-colors">
                            {worker.name}
                          </h3>
                          <p className="text-gray-600 text-sm">{worker.trade || 'Meșter Universal'}</p>
                        </div>
                      </div>

                      {/* Stats */}
                      <div className="flex gap-3 mb-4">
                        <div className="flex items-center gap-1 text-sm">
                          <Star className="w-4 h-4 text-yellow-400 fill-yellow-400" />
                          <span className="font-bold text-gray-900">{worker.averageRating.toFixed(1)}</span>
                          <span className="text-gray-500">({worker.reviewCount})</span>
                        </div>
                        <div className="flex items-center gap-1 text-sm">
                          <Shield className="w-4 h-4 text-green-500" />
                          <span className="font-bold text-gray-900">{worker.completed_projects_count || '0'}</span>
                          <span className="text-gray-500">proiecte</span>
                        </div>
                      </div>

                      {/* Location */}
                      {worker.address && (
                        <div className="flex items-center gap-2 text-sm text-gray-600 mb-4">
                          <MapPin className="w-4 h-4 text-gray-400 flex-shrink-0" />
                          <span className="truncate">{worker.address}</span>
                        </div>
                      )}

                      {/* Availability */}
                      <div className="flex items-center gap-2 text-sm text-green-600 bg-green-50 rounded-lg py-2 px-3 mb-4">
                        <Clock className="w-4 h-4" />
                        <span>Disponibil pentru lucrări</span>
                      </div>

                      {/* CTA Button */}
                      <button className="w-full py-2.5 bg-gray-900 text-white rounded-xl font-medium
                                     transition-all group-hover:bg-orange-500 flex items-center justify-center gap-2">
                        <span>Vezi profilul</span>
                        <ArrowRight className="w-4 h-4 transition-transform group-hover:translate-x-1" />
                      </button>
                    </div>
                  </Link>
                </motion.div>
              ))}
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
          className="text-center mt-12 px-4"
        >
          <Link
            href="/meseriasi"
            className="inline-flex items-center px-6 py-3 text-base font-medium rounded-xl
                     text-white bg-orange-500 hover:bg-orange-600 transition-all duration-300
                     shadow-lg shadow-orange-500/20 hover:shadow-orange-500/30 gap-2"
          >
            Vezi toți meseriașii
            <ArrowRight className="w-5 h-5" />
          </Link>
        </motion.div>
      </div>
    </section>
  );
}