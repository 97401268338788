'use client'

import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { 
  Home, MessageCircle, Bell, Menu, User, LogOut, Search, 
  LayoutDashboard, Star, Briefcase, Plus, Shield, 
  Settings, Hammer, FileText 
} from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'
import { useAuth } from '@/context/AuthContext'
import { supabase } from '@/lib/supabase'
import UnreadMessagesCount from '@/components/notifications/unreadMessagesCount'
import MobileNavigation from './navigation/mobileNav'
import Image from 'next/image';
import { serviceCategories } from '@/data/serviceCategories'

const navbarClasses = "fixed left-0 top-20 h-[calc(100%-5rem)] bg-gray-900 z-40 overflow-y-auto overflow-x-hidden navbar hidden md:block border-r border-gray-800"

export default function Navbar() {
  const { user, signOut, loading } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [showServicesMenu, setShowServicesMenu] = useState(false)
  const [viewAsWorker, setViewAsWorker] = useState(false)
  const router = useRouter()
  const mobileMenuRef = useRef(null)
  const [unreadCount, setUnreadCount] = useState(0)
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0)
  const [userRole, setUserRole] = useState(null)
  const servicesMenuRef = useRef(null)
  const closeTimeoutRef = useRef(null)

  const handleMouseEnter = () => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current)
    }
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    closeTimeoutRef.current = setTimeout(() => {
      setIsHovered(false)
      setShowServicesMenu(false)
    }, 100)
  }

  const closeAll = () => {
    setShowServicesMenu(false)
    setIsHovered(false)
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current)
    }
  }

  const handleServicesMouseEnter = () => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current)
    }
    setShowServicesMenu(true)
  }

  const handleServicesMouseLeave = () => {
    closeTimeoutRef.current = setTimeout(() => {
      closeAll()
    }, 100)
  }

  const handleServiceClick = () => {
    closeAll()
  }

  useEffect(() => {
    return () => {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current)
      }
    }
  }, [])

  useEffect(() => {
    const checkUserRole = async () => {
      if (!user) {
        setUserRole(null);
        return;
      }
      
      const { data: profile } = await supabase
        .from('profiles')
        .select('role')
        .eq('id', user.id)
        .single();

      setUserRole(profile?.role);
    };

    checkUserRole();
  }, [user]);

  const navItems = useMemo(() => {
    const allItems = [
      { name: 'Acasă', href: '/', icon: Home, showFor: ['client', 'worker', null] },
      { name: 'Cere oferta', href: '/new-post', icon: Plus, showFor: ['client', null] },
      { name: 'Lucrări Active', href: '/lucrari-actuale', icon: Briefcase, showFor: [null] },
      { name: 'Servicii', href: '/servicii', icon: Search, showFor: ['client', 'worker', null], hasDropdown: true },
      { name: 'Abonamente Pro', href: '/membership', icon: Star, showFor: ['worker', null] },
      { name: 'Verifica Meseriasi', href: '/meseriasi', icon: Hammer, showFor: ['client', 'worker', null] },
      { name: 'Blog', href: '/blog', icon: FileText, showFor: ['client', 'worker', null] },
    ];

    return allItems.filter(item => 
      item.showFor.includes(userRole) || 
      (item.showFor.includes(null) && !user)
    );
  }, [userRole]);

  const authItems = useMemo(() => {
    if (!user) return [];
    
    return [
      { 
        name: 'Panou de control', 
        icon: LayoutDashboard,
        onClick: () => {
          let dashboardRoute;
          if (user.user_metadata.role === 'client') {
            dashboardRoute = '/dashboard/client';
          } else if (user.user_metadata.role === 'worker') {
            dashboardRoute = '/dashboard/worker';
          } else if (user.user_metadata.role === 'admin') {
            dashboardRoute = '/dashboard/admin';
          }
          router.push(dashboardRoute);
        }
      },
      {
        name: 'Mesaje',
        icon: MessageCircle,
        onClick: () => router.push('/messages'),
        badge: unreadMessagesCount > 0 ? unreadMessagesCount : null
      },
      {
        name: 'Notificari',
        icon: Bell,
        onClick: () => router.push('/notifications'),
        badge: unreadCount > 0 ? unreadCount : null
      },
      
      { name: 'Setari', icon: Settings, onClick: () => router.push('/profil') },
      { name: 'Deconectare', icon: LogOut, onClick: async () => {
        try {
          const success = await signOut();
          if (success) {
            setUserRole(null);
            router.push('/');
          }
        } catch (error) {
          console.error('Error signing out:', error);
          window.location.href = '/';
        }
      }},
    ]
  }, [user, router, signOut, unreadCount, unreadMessagesCount])

  const renderNavItems = useCallback((isMobile = false) => (
    <div className={isMobile ? "mb-8" : "space-y-2"}>
      {navItems.map((item) => (
        <NavItem 
          key={item.name} 
          item={item} 
          isMobile={isMobile} 
          isHovered={isHovered}
          onClick={() => setIsOpen(false)} 
          onMouseEnter={() => item.hasDropdown && setShowServicesMenu(true)}
          onMouseLeave={() => item.hasDropdown && setShowServicesMenu(false)}
        />
      ))}
    </div>
  ), [navItems, setIsOpen, isHovered])

  const renderAuthItems = useCallback((isMobile = false) => {
    if (loading) {
      return (
        <div className="flex flex-col space-y-4">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="w-9 h-9 bg-white bg-opacity-20 rounded-full"></div>
          ))}
        </div>
      )
    }

    const items = (
      <div className={`flex flex-col space-y-2 ${isMobile ? '' : 'mt-auto'}`}>
        {authItems && authItems.map((item, index) => (
          <AuthItem 
            key={index} 
            item={item} 
            isHovered={isHovered}
            onClick={() => setIsOpen(false)} 
            isMobile={isMobile}
          />
        ))}
      </div>
    )

    if (!user) {
      return (
        <div className={`flex flex-col space-y-2 ${isMobile ? '' : 'mt-auto'}`}>
          {items}
          <AuthLink 
            href="/login" 
            icon={User} 
            text="Autentificare" 
            isMobile={isMobile} 
            isHovered={isHovered}
            onClick={() => setIsOpen(false)} 
          />
          <AuthLink 
            href="/register/worker" 
            icon={Briefcase} 
            text="Înregistrare Firmă" 
            isMobile={isMobile} 
            isHovered={isHovered}
            onClick={() => setIsOpen(false)} 
          />
        </div>
      )
    }

    return items
  }, [user, authItems, loading, setIsOpen, isHovered])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const checkUnreadNotifications = useCallback(async () => {
    if (!user) {
      console.log('No user found, skipping notifications check');
      return;
    }
    
    try {
      const { data: notificationsData, error: notificationsError } = await supabase
        .from('notifications')
        .select('id')
        .eq('user_id', user.id)
        .eq('is_read', false)
        .limit(100);

      if (notificationsError) {
        console.error('Supabase error:', notificationsError);
        return;
      }

      setUnreadCount(notificationsData?.length || 0);
    } catch (error) {
      console.error('Error checking notifications:', error);
      setUnreadCount(0);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      checkUnreadNotifications();

      const interval = setInterval(checkUnreadNotifications, 30000);

      const subscription = supabase
        .channel('notifications')
        .on('postgres_changes', {
          event: 'INSERT',
          schema: 'public',
          table: 'notifications',
          filter: `user_id=eq.${user.id}`
        }, () => {
          checkUnreadNotifications();
        })
        .on('postgres_changes', {
          event: 'UPDATE',
          schema: 'public',
          table: 'notifications',
          filter: `user_id=eq.${user.id}`
        }, () => {
          checkUnreadNotifications();
        })
        .on('postgres_changes', {
          event: 'DELETE',
          schema: 'public',
          table: 'notifications',
          filter: `user_id=eq.${user.id}`
        }, () => {
          checkUnreadNotifications();
        })
        .subscribe();

      return () => {
        clearInterval(interval);
        subscription.unsubscribe();
      };
    }
  }, [user, checkUnreadNotifications]);

  return (
    <>
      {user && <UnreadMessagesCount setUnreadMessagesCount={setUnreadMessagesCount} />}
      <motion.nav 
        className={navbarClasses}
        initial={false}
        animate={{ width: isHovered ? '16rem' : '4.3rem' }}
        transition={{ duration: 0.15, ease: [0.23, 1, 0.32, 1] }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="flex flex-col h-full p-4 w-full">
          <div className="flex-grow space-y-1">
            {renderNavItems()}
          </div>
          <div className="mt-auto space-y-1">
            {renderAuthItems()}
          </div>
        </div>
      </motion.nav>

      {/* Services Dropdown Menu */}
      <AnimatePresence mode="wait">
        {showServicesMenu && isHovered && (
          <motion.div
            ref={servicesMenuRef}
            initial={{ opacity: 0, y: -5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1, ease: "easeOut" }}
            className="fixed left-64 top-0 mt-[108px] bg-slate-800/90 backdrop-blur-sm rounded-lg shadow-2xl p-4 z-50 w-[900px] border border-slate-700/50"
            onMouseEnter={handleServicesMouseEnter}
            onMouseLeave={handleServicesMouseLeave}
          >
            <div className="absolute -left-3 top-[98px] w-6 h-6 bg-slate-800/90 backdrop-blur-sm border-l border-t border-slate-700/50 transform -translate-y-1/2 rotate-45 shadow-lg" />

            {/* User Type Selection */}
            <div className="relative mb-3 border-b border-slate-700/50 pb-3">
              <div className="flex items-center justify-between mb-2">
                <h3 className="text-white text-base font-semibold">
                  {viewAsWorker ? "Găsește oportunități de lucru" : "Caută servicii"}
                </h3>
                <button
                  onClick={() => setViewAsWorker(!viewAsWorker)}
                  className="text-xs bg-slate-900/80 hover:bg-slate-700 text-white px-3 py-1.5 rounded-lg transition-all duration-200 flex items-center gap-2"
                >
                  <span className={`w-1.5 h-1.5 rounded-full ${viewAsWorker ? 'bg-blue-400' : 'bg-green-400'}`} />
                  {viewAsWorker ? "Vezi ca și client" : "Vezi ca și meseriaș"}
                </button>
              </div>

              {viewAsWorker ? (
                <div className="bg-slate-900/50 rounded-lg p-3 border border-slate-700">
                  <p className="text-slate-300 text-sm">Creează-ți un profil profesional și primește cereri de ofertă direct de la clienți.</p>
                  <Link
                    href="/register/worker"
                    className="mt-2 inline-flex items-center gap-1 text-blue-400 hover:text-blue-300 text-sm group"
                    onClick={handleServiceClick}
                  >
                    Înregistrează-te ca meseriaș
                    <span className="opacity-0 group-hover:opacity-100 transition-all duration-100 transform group-hover:translate-x-1">→</span>
                  </Link>
                </div>
              ) : (
                <div className="bg-slate-900/50 rounded-lg p-3 border border-slate-700">
                  <p className="text-slate-300 text-sm">
                    <span className="text-green-400 font-medium">Gratuit</span> - Postează cererea ta în mai puțin de 1 minut și primește oferte de la meseriași verificați.
                  </p>
                  <Link
                    href="/new-post"
                    className="mt-2 inline-flex items-center gap-1 text-blue-400 hover:text-blue-300 text-sm group"
                    onClick={handleServiceClick}
                  >
                    Postează o cerere
                    <span className="opacity-0 group-hover:opacity-100 transition-all duration-100 transform group-hover:translate-x-1">→</span>
                  </Link>
                </div>
              )}
            </div>

            {/* Services Categories - Grid more compact */}
            <motion.div 
              className="grid grid-cols-3 gap-3"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.1, delay: 0.05 }}
            >
              {serviceCategories.map((category, idx) => (
                <motion.div 
                  key={category.id} 
                  initial={{ opacity: 0, y: -5 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.1, delay: idx * 0.03 }}
                  className="group bg-slate-900/50 hover:bg-slate-900 rounded-lg p-3 transition-all duration-200 border border-slate-700"
                >
                  <Link
                    href={viewAsWorker ? `/register/worker?category=${category.id}` : `/servicii/${category.id}`}
                    className="relative text-white text-base font-semibold hover:text-blue-400 transition-colors duration-200 flex items-center gap-2 group"
                    onClick={handleServiceClick}
                  >
                    {category.name}
                    <span className="opacity-0 group-hover:opacity-100 transition-all duration-100 transform group-hover:translate-x-1">→</span>
                  </Link>
                  <p className="text-slate-400 text-xs mt-1">{category.description}</p>
                  <div className="space-y-0.5 mt-2">
                    {category.subcategories.slice(0, 4).map((subcategory, subIdx) => (
                      <motion.div
                        key={subcategory.id}
                        initial={{ opacity: 0, x: -5 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.1, delay: (idx * 0.03) + (subIdx * 0.02) }}
                      >
                        <Link
                          href={viewAsWorker 
                            ? `/register/worker?category=${category.id}&subcategory=${subcategory.id}`
                            : `/servicii/${category.id}/${subcategory.id}`
                          }
                          className="text-slate-300 hover:text-white text-sm py-1.5 transition-all duration-100 block hover:translate-x-2 transform flex items-center gap-2 group"
                          onClick={handleServiceClick}
                        >
                          <span className="w-1 h-1 rounded-full bg-blue-500/50 group-hover:bg-blue-500 transition-colors duration-100" />
                          {subcategory.name}
                        </Link>
                      </motion.div>
                    ))}
                    {category.subcategories.length > 4 && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.1, delay: (idx * 0.03) + 0.2 }}
                      >
                        <Link
                          href={viewAsWorker ? `/register/worker?category=${category.id}` : `/servicii/${category.id}`}
                          className="text-blue-400 hover:text-blue-300 text-sm transition-all duration-100 block mt-3 flex items-center gap-2 group hover:gap-3 pt-2 border-t border-slate-700/50"
                          onClick={handleServiceClick}
                        >
                          Vezi toate {viewAsWorker ? "oportunitățile" : "serviciile"}
                          <span className="text-xs opacity-0 group-hover:opacity-100 transform group-hover:translate-x-1 transition-all duration-100">→</span>
                        </Link>
                      </motion.div>
                    )}
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <MobileNavigation 
        user={user}
        unreadMessagesCount={unreadMessagesCount}
        unreadCount={unreadCount}
        navItems={navItems}
        authItems={authItems}
        signOut={signOut}
      />
    </>
  )
}

const NavItem = React.memo(({ item, isMobile, onClick, isHovered, onMouseEnter, onMouseLeave }) => (
  <Link
    href={item.href}
    className={`group flex items-center text-gray-300 hover:text-white transition-colors duration-200 h-10 w-full relative ${
      item.hasDropdown ? 'hover:bg-gray-800 rounded-lg' : ''
    }`}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <div className={`w-9 h-9 rounded-full bg-gray-800 group-hover:bg-gray-700 flex items-center justify-center flex-shrink-0 transition-colors duration-200 ${
      item.hasDropdown ? 'group-hover:bg-blue-500/20' : ''
    }`}>
      <item.icon className="h-5 w-5" />
    </div>
    {(isMobile || isHovered) && (
      <motion.span
        className="ml-4 whitespace-nowrap"
        initial={{ opacity: 0, x: -10 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -10 }}
        transition={{ duration: 0.15 }}
      >
        {item.name}
      </motion.span>
    )}
  </Link>
))

const AuthItem = React.memo(({ item, isHovered, onClick, isMobile }) => (
  <button
    className="group flex items-center text-white hover:text-gray-200 transition-colors duration-200 h-10 w-full relative"
    onClick={() => {
      item.onClick();
      onClick();
    }}
  >
    <div className="w-9 h-9 rounded-full bg-gray-800 group-hover:bg-gray-700 flex items-center justify-center flex-shrink-0 relative transition-colors duration-200">
      <item.icon className="h-6 w-6" />
      {item.badge && (
        <span className="absolute -top-1 -right-1 bg-gray-200 text-gray-900 text-xs rounded-full h-5 w-5 flex items-center justify-center font-medium">
          {item.badge}
        </span>
      )}
    </div>
    {(isMobile || isHovered) && (
      <motion.span
        className="ml-4 whitespace-nowrap"
        initial={{ opacity: 0, x: -10 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -10 }}
        transition={{ duration: 0.2 }}
      >
        {item.name}
      </motion.span>
    )}
  </button>
));

const AuthLink = React.memo(({ href, icon: Icon, text, onClick, isMobile, isHovered }) => (
  <Link 
    href={href} 
    className={`group flex items-center text-white hover:text-gray-200 transition-colors duration-200 h-10 w-full`} 
    onClick={onClick}
  >
    <div className="w-9 h-9 rounded-full bg-gray-800 group-hover:bg-gray-700 flex items-center justify-center flex-shrink-0 transition-colors duration-200">
      <Icon className="h-6 w-6" />
    </div>
    {(isMobile || isHovered) && (
      <motion.span
        className="ml-4 whitespace-nowrap"
        initial={{ opacity: 0, x: -10 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -10 }}
        transition={{ duration: 0.2 }}
      >
        {text}
      </motion.span>
    )}
  </Link>
));