'use client'

import { useState, useCallback, useMemo, useRef, useEffect } from 'react'
import { useRouter } from 'next/navigation'
import { Search, ArrowRight } from 'lucide-react'

const normalizeString = (str) => {
  return str
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // Elimină diacriticele
    .replace(/[^a-z0-9\s]/g, "") // Păstrează doar litere, numere și spații
}

const tradeCategories = [
  {
    category: "Renovări Interioare",
    normalizedCategory: "renovari interioare",
    trades: [
      { 
        id: 'zugrav', 
        name: 'Zugrăveli & Vopsitorii', 
        normalizedName: 'zugraveli vopsitorii',
        services: ['zugraveala', 'vopsitorii-decorative', 'decorativa-interior']
      },
      { 
        id: 'faiantar', 
        name: 'Gresie & Faianță', 
        normalizedName: 'gresie faianta',
        services: ['gresie-faianta', 'placaje-marmura']
      },
      { 
        id: 'parchetar', 
        name: 'Montaj & Rașchetare Parchet', 
        normalizedName: 'montaj raschetare parchet',
        services: ['parchet', 'raschetare-parchet', 'reconditionare-parchet'] 
      },
      { 
        id: 'tamplar', 
        name: 'Tâmplărie & Uși', 
        normalizedName: 'tamplarie usi',
        services: ['montaj-usi', 'reparatii-usi', 'reconditionare-usi', 'reparatii-mobila'] 
      },
      { 
        id: 'rigipsar', 
        name: 'Rigips & Tavane False', 
        normalizedName: 'rigips tavane false',
        services: ['rigips', 'tavane-false', 'pereti-despartitori'] 
      }
    ]
  },
  {
    category: "Instalații",
    normalizedCategory: "instalatii",
    trades: [
      { 
        id: 'instalator', 
        name: 'Instalații Sanitare', 
        normalizedName: 'instalatii sanitare',
        services: ['sanitare', 'termice', 'gaz', 'desfundare-canalizare'] 
      },
      { 
        id: 'electrician', 
        name: 'Instalații Electrice', 
        normalizedName: 'instalatii electrice',
        services: ['electrice', 'automatizari', 'iluminat-exterior'] 
      },
      { 
        id: 'instalator-centrale', 
        name: 'Centrale & Termice', 
        normalizedName: 'centrale termice',
        services: ['centrale-termice', 'pompe-caldura', 'panouri-solare'] 
      },
      { 
        id: 'frigotehnist', 
        name: 'AC & Ventilație', 
        normalizedName: 'ac ventilatie',
        services: ['aer-conditionat', 'ventilatie'] 
      }
    ]
  },
  {
    category: "Construcții",
    normalizedCategory: "constructii",
    trades: [
      { 
        id: 'constructor', 
        name: 'Constructor Case', 
        normalizedName: 'constructor case',
        services: ['zidarie', 'fundatii', 'consolidari', 'case-lemn', 'acoperisuri'] 
      },
      { 
        id: 'zidar', 
        name: 'Zidărie & Tencuieli', 
        normalizedName: 'zidarie tencuieli',
        services: ['zidarie', 'fundatii', 'tencuiala'] 
      },
      { 
        id: 'dulgher', 
        name: 'Dulgherie & Acoperișuri', 
        normalizedName: 'dulgherie acoperisuri',
        services: ['case-lemn', 'acoperisuri', 'structuri-metalice'] 
      },
      { 
        id: 'fierar-betonist', 
        name: 'Fundații & Structuri', 
        normalizedName: 'fundatii structuri',
        services: ['fundatii', 'consolidari'] 
      },
      { 
        id: 'demolator', 
        name: 'Demolări & Consolidări', 
        normalizedName: 'demolari consolidari',
        services: ['demolari', 'consolidari'] 
      }
    ]
  },
  {
    category: "Amenajări Exterioare",
    normalizedCategory: "amenajari exterioare",
    trades: [
      { 
        id: 'peisagist', 
        name: 'Grădinărit & Peisagistică', 
        normalizedName: 'gradinarit peisagistica',
        services: ['gradinarit', 'peisagistica', 'irigatie'] 
      },
      { 
        id: 'pavator', 
        name: 'Pavaje & Alei', 
        normalizedName: 'pavaje alei',
        services: ['pavaje', 'alei', 'garduri'] 
      }
    ]
  },
  {
    category: "Servicii Specializate",
    normalizedCategory: "servicii specializate",
    trades: [
      { 
        id: 'arhitect', 
        name: 'Arhitectură & Proiectare', 
        normalizedName: 'arhitectura proiectare',
        services: ['proiectare', 'consultanta'] 
      },
      { 
        id: 'firma-curatenie', 
        name: 'Curățenie Profesională', 
        normalizedName: 'curatenie profesionala',
        services: ['curatenie'] 
      },
      { 
        id: 'mentenanta-cladiri', 
        name: 'Mentenanță Clădiri', 
        normalizedName: 'mentenanta cladiri',
        services: ['mentenanta-cladiri'] 
      }
    ]
  },
  {
    category: "Reparații și Întreținere",
    trades: [
      { id: 'service-centrale', name: 'Service Centrale Termice', services: ['intretinere-centrale', 'reparatii-centrale'] },
      { id: 'service-termopane', name: 'Service Termopane', services: ['reparatii-termopane'] },
      { id: 'service-acoperisuri', name: 'Service Acoperișuri', services: ['reparatii-acoperisuri'] },
    ]
  }
]

export default function ClientHeroForm() {
  const router = useRouter()
  const [selectedTrade, setSelectedTrade] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)
  const searchInputRef = useRef(null)

  // Adăugăm handler pentru click outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && 
          !dropdownRef.current.contains(event.target) && 
          !searchInputRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  // Filter trades based on search query
  const filteredCategories = useMemo(() => {
    if (!searchQuery) return tradeCategories
    
    const normalizedQuery = normalizeString(searchQuery)
    
    return tradeCategories.map(category => ({
      ...category,
      trades: category.trades.filter(trade => {
        // Verificăm existența proprietăților înainte de a le folosi
        const nameMatch = trade.name ? normalizeString(trade.name).includes(normalizedQuery) : false
        const categoryMatch = category.category ? normalizeString(category.category).includes(normalizedQuery) : false
        const normalizedNameMatch = trade.normalizedName ? trade.normalizedName.includes(normalizedQuery) : false
        const servicesMatch = trade.services ? trade.services.some(service => 
          service ? normalizeString(service).includes(normalizedQuery) : false
        ) : false

        return nameMatch || categoryMatch || normalizedNameMatch || servicesMatch
      })
    })).filter(category => category.trades.length > 0)
  }, [searchQuery])

  const handleTradeSelect = useCallback((tradeId, tradeName) => {
    setSelectedTrade(tradeId) // Salvăm doar ID-ul
    setSearchQuery(tradeName)
    setIsOpen(false)
  }, [])

  const handleSubmit = useCallback((e) => {
    e.preventDefault()
    if (selectedTrade) {
      router.push(`/new-post?trade=${selectedTrade}`) // Folosim doar ID-ul pentru redirect
    }
  }, [selectedTrade, router])

  return (
    <form onSubmit={handleSubmit} className="relative max-w-[700px] mx-auto">
      <div className="flex flex-col sm:flex-row gap-2">
        <div className="relative flex-1">
          <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            ref={searchInputRef}
            type="text"
            className="w-full pl-12 pr-4 py-3.5 bg-white text-gray-700 rounded-lg border-0
              focus:ring-2 focus:ring-gray-900 focus:border-transparent
              placeholder:text-gray-500 shadow-sm text-[15px]"
            placeholder="Ce tip de meșter cauți?"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value)
              setIsOpen(true)
            }}
            onFocus={() => setIsOpen(true)}
          />

          {/* Suggestions Panel */}
          {isOpen && (
            <div 
              ref={dropdownRef}
              className="absolute left-0 right-0 mt-1 bg-white rounded-lg shadow-lg border border-gray-100
                overflow-hidden z-50"
            >
              <div className="max-h-[300px] overflow-y-auto">
                {filteredCategories.map((category) => (
                  <div key={category.category} className="px-2 py-1">
                    <div className="px-2 py-1.5">
                      <h3 className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {category.category}
                      </h3>
                    </div>
                    {category.trades.map((trade) => (
                      <div
                        key={trade.id}
                        onClick={() => handleTradeSelect(trade.id, trade.name)}
                        className={`px-3 py-2 rounded-md cursor-pointer transition-colors
                          ${selectedTrade === trade.id 
                            ? 'bg-gray-100 text-gray-900' 
                            : 'hover:bg-gray-50 text-gray-700'
                          }`}
                      >
                        <span className="block text-sm font-medium">{trade.name}</span>
                        <span className="text-xs text-gray-500 mt-0.5">
                          {trade.services.slice(0, 3).join(', ')}
                          {trade.services.length > 3 && '...'}
                        </span>
                      </div>
                    ))}
                  </div>
                ))}

                {filteredCategories.length === 0 && (
                  <div className="px-4 py-8 text-center">
                    <p className="text-sm text-gray-500">
                      Nu am găsit servicii pentru "{searchQuery}"
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <button
          type="submit"
          disabled={!selectedTrade}
          className={`py-3.5 px-6 rounded-lg font-medium flex items-center justify-center gap-2 
            whitespace-nowrap transition-all duration-200 text-[15px]
            ${!selectedTrade 
              ? 'bg-orange-500/80 text-white cursor-not-allowed' 
              : 'bg-orange-500 text-white hover:bg-orange-600 active:scale-[0.98]'
            }
          `}
        >
          <span>Caută Meserias</span>
          <ArrowRight className="w-5 h-5" />
        </button>
      </div>
    </form>
  )
} 