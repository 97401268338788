"use client";
import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';
import { useRouter } from 'next/navigation';
import { trackTikTokEvent, TIKTOK_EVENTS, identifyTikTokUser } from '@/utils/analytics';

const getSiteUrl = () => {
  if (typeof window !== 'undefined') {
    return window.location.origin
  }
  if (process.env.NEXT_PUBLIC_SITE_URL) {
    return process.env.NEXT_PUBLIC_SITE_URL
  }
  return 'http://localhost:3000'
}

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState(null);
  const router = useRouter();

  useEffect(() => {
    const initSession = async () => {
      try {
        const { data: { session: currentSession }, error } = await supabase.auth.getSession();
        if (error) throw error;
        
        setSession(currentSession);

        if (currentSession?.user) {
          console.log('Found session for user:', currentSession.user.id);
          
          // Try to get existing profile
          let { data: profile, error: profileError } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', currentSession.user.id)
            .single();

          // If no profile exists, create one and setup worker benefits if needed
          if (!profile && currentSession.user.user_metadata) {
            console.log('Creating new profile for user');
            const { data: newProfile, error: createError } = await supabase
              .from('profiles')
              .insert([{
                id: currentSession.user.id,
                email: currentSession.user.email,
                name: currentSession.user.user_metadata.name,
                role: currentSession.user.user_metadata.role,
                trade: currentSession.user.user_metadata.trade || null,
                created_at: new Date().toISOString()
              }])
              .select()
              .single();

            if (createError) {
              console.error('Error creating profile:', createError);
            } else {
              profile = newProfile;
              console.log('New profile created:', profile);

              // If worker, setup membership and credits
              if (currentSession.user.user_metadata.role === 'worker') {
                console.log('Setting up worker benefits...');
                
                // Get Basic membership
                const { data: basicMembership, error: membershipError } = await supabase
                  .from('memberships')
                  .select('id')
                  .eq('name', 'Basic')
                  .single();

                if (!membershipError && basicMembership) {
                  // Create worker membership
                  await supabase
                    .from('worker_memberships')
                    .insert([{
                      worker_id: currentSession.user.id,
                      membership_id: basicMembership.id,
                      status: 'active'
                    }]);

                  // Add initial credits
                  await supabase
                    .from('credits')
                    .insert([{
                      worker_id: currentSession.user.id,
                      amount: 3,
                      last_monthly_credit_at: new Date().toISOString()
                    }]);

                  // Record welcome bonus
                  await supabase
                    .from('credit_transactions')
                    .insert([{
                      worker_id: currentSession.user.id,
                      amount: 3,
                      type: 'welcome_bonus',
                      description: 'Bonus de bun venit - 3 credite gratuite',
                      created_at: new Date().toISOString()
                    }]);

                  console.log('Worker benefits setup complete');
                }
              }
            }
          }

          console.log('Final profile data:', profile);
          
          setUser({
            ...currentSession.user,
            profile: profile || {}
          });

          // Redirect based on role if we're on login page
          if (window.location.pathname === '/login' && profile?.role) {
            router.replace(`/dashboard/${profile.role}`);
          }

          identifyTikTokUser(currentSession.user);
        } else {
          console.log('No session found');
          setUser(null);
        }
      } catch (error) {
        console.error('Error in initSession:', error);
      } finally {
        setLoading(false);
      }
    };

    initSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      console.log("Auth state changed:", _event);
      setSession(session);
      initSession();
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [router]);

  const signUp = async ({ email, password, options }) => {
    try {
      // Verificăm mai întâi dacă emailul există deja în profiles
      const { data: existingProfile, error: profileError } = await supabase
        .from('profiles')
        .select('id, role')
        .eq('email', email)
        .single();

      if (existingProfile) {
        return { 
          data: null, 
          error: { 
            message: 'Acest email este deja înregistrat. Te rugăm să folosești alt email sau să te autentifici.' 
          } 
        };
      }

      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          ...options,
          emailRedirectTo: `${getSiteUrl()}/auth/callback?type=signup`
        }
      });

      if (error) throw error;

      // Track signup event
      trackTikTokEvent(TIKTOK_EVENTS.SIGN_UP, {
        email: email,
        role: options?.data?.role
      });

      return { data, error: null };
    } catch (error) {
      console.error('Signup error:', error);
      return { data: null, error };
    }
  };

  const signOut = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      
      if (session) {
        await supabase.auth.signOut();
      }
      
      setUser(null);
      
      document.cookie = 'supabase-auth-token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT';
      
      return true;
      
    } catch (error) {
      console.error('Error during sign out:', error.message);
      setUser(null);
      document.cookie = 'supabase-auth-token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT';
      return false;
    }
  };

  const signIn = async ({ email, password }) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({ 
        email, 
        password 
      });
      if (error) throw error;
      
      // Track login event
      trackTikTokEvent(TIKTOK_EVENTS.LOGIN, {
        email: email
      });

      // Identify user
      if (data?.user) {
        await identifyTikTokUser(data.user);
      }

      setUser(data.user);
      return data;
    } catch (error) {
      console.error('Sign in error:', error);
      throw error;
    }
  };

  // Track complete registration when user confirms email
  useEffect(() => {
    if (session?.user) {
      trackTikTokEvent(TIKTOK_EVENTS.COMPLETE_REGISTRATION, {
        user_id: session.user.id,
        role: session.user.user_metadata?.role
      });
      identifyTikTokUser(session.user);
    }
  }, [session?.user?.id]);

  const value = {
    user,
    loading,
    signUp,
    signIn,
    signOut,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
