'use client'

import Link from 'next/link'
import { ArrowRight, Shield, Clock, Star } from 'lucide-react'
import ServiceRequestForm from '@/components/forms/ServiceRequestForm'
import { tradesMapping } from '@/data/serviceCategories'

const DynamicHero = ({ 
  breadcrumbs,
  title,
  subtitle,
  description,
  showForm = true,
  showCTA = true,
  cityFromUrl,
  className = "",
  currentCategory,
}) => {
  const relevantTrades = Object.entries(tradesMapping)
    .filter(([trade, [category]]) => category === currentCategory)
    .map(([trade]) => trade);

  return (
    <section className={`relative overflow-hidden text-white min-h-[70vh] flex items-center justify-center ${className} py-12`}>
      {/* Background Layers */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-gray-800 to-black" />
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900/50 to-transparent" />
      </div>

      <div className="relative z-10 container mx-auto px-6">
        {breadcrumbs && (
          <nav className="mb-6" aria-label="Breadcrumb">
            <ol className="flex flex-wrap items-center space-x-2 text-gray-400 text-sm">
              {breadcrumbs.map((crumb, index) => (
                <li key={index} className="flex items-center">
                  {index < breadcrumbs.length - 1 ? (
                    <>
                      <Link href={crumb.href} className="hover:text-gray-200 transition duration-300">
                        {crumb.text}
                      </Link>
                      <span className="mx-2 text-gray-500">›</span>
                    </>
                  ) : (
                    <span className="text-gray-200">{crumb.text}</span>
                  )}
                </li>
              ))}
            </ol>
          </nav>
        )}

        <div className="grid lg:grid-cols-2 gap-8 lg:gap-12 items-center max-w-7xl mx-auto">
          <div className="space-y-8">
            <div className="space-y-4">
              <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold animate-fade-in">
                {title}
              </h1>

              {subtitle && (
                <h2 className="text-xl sm:text-2xl text-gray-300 font-light leading-relaxed animate-fade-in">
                  {subtitle}
                </h2>
              )}

              {description && (
                <p className="text-gray-400 text-lg animate-fade-in">
                  {description}
                </p>
              )}
            </div>

            {/* Beneficii simplificate */}
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 animate-fade-in">
              <div className="flex items-center space-x-3">
                <Shield className="w-6 h-6 text-blue-400" />
                <span className="text-gray-300">Meseriași Verificați</span>
              </div>
              <div className="flex items-center space-x-3">
                <Clock className="w-6 h-6 text-blue-400" />
                <span className="text-gray-300">Răspuns Rapid</span>
              </div>
              <div className="flex items-center space-x-3">
                <Star className="w-6 h-6 text-blue-400" />
                <span className="text-gray-300">Calitate Garantată</span>
              </div>
            </div>

            {showCTA && (
              <div className="pt-4 animate-fade-in hidden md:block">
                <Link 
                  href="/register/worker"
                  className="inline-flex items-center bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg font-medium transition duration-300"
                >
                  Devino Meseriaș Verificat
                  <ArrowRight className="ml-2 w-5 h-5" />
                </Link>
              </div>
            )}
          </div>

          {showForm && (
            <div className="lg:mt-0 animate-fade-in">
              <ServiceRequestForm 
                cityFromUrl={cityFromUrl} 
                category={currentCategory}
                relevantTrades={relevantTrades}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default DynamicHero