import React from 'react'
import Image from 'next/image'
import ClientHeroForm from './ClientHeroForm'

export default function Hero() {
  return (
    <section className="relative min-h-screen bg-gradient-to-br from-indigo-700 via-purple-700 to-pink-600 overflow-hidden">
      <div className="absolute inset-0">
        <Image
          src="/ceva.jpeg" // Use your own image or remove this if not needed
          alt="Modern Workspace"
          priority
          fill
          className="object-cover object-center w-full h-full brightness-75"
          sizes="100vw"
          quality={90}
        />
        <div className="absolute inset-0 bg-gradient-to-br from-gray-900/70 to-gray-800/70" />
      </div>

      <div className="relative z-10 container mx-auto px-4 flex items-center justify-center min-h-screen">
        <div className="w-full max-w-3xl mx-auto text-center space-y-6">
          {/* Hero Text */}
          <h1 className="text-3xl sm:text-4xl font-black text-white tracking-tight leading-snug">
            Găsește Meseriași de Încredere Instant
          </h1>
          <p className="text-xl sm:text-2xl text-gray-200">
            Transformă-ți spațiul cu un singur click
          </p>

       {/* Search Form */}
<div >
  <ClientHeroForm />
</div>

          {/* Features */}
          <div className="flex flex-wrap justify-center space-x-4 space-y-2 text-gray-200">
            {[
              { icon: 'check', text: 'Verificați' },
              { icon: 'zap', text: 'Rapid' },
              { icon: 'shield', text: 'De Încredere' }
            ].map(({ icon, text }, index) => (
              <div key={index} className="flex items-center ">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={icon === 'check' ? 'M5 13l4 4L19 7' : icon === 'zap' ? 'M13 10V3L4 14h7v7l9-11h-7z' : 'M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z'} />
                </svg>
                <span className="font-semibold">{text}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}