import { useState, useRef, useEffect } from 'react';
import { locations, allLocations, getDisplayLocation } from '@/data/cities';
import { FaSearch } from 'react-icons/fa';

const CityAutocomplete = ({ onSelect, initialValue = '' }) => {
  const [searchTerm, setSearchTerm] = useState(initialValue);
  const [suggestions, setSuggestions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setIsOpen(true);

    if (value.length > 0) {
      const filteredSuggestions = allLocations
        .filter(location =>
          location.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          .includes(value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
        )
        .sort((a, b) => {
          // Prioritizează potrivirile exacte și începuturile de cuvinte
          const aLower = a.toLowerCase();
          const bLower = b.toLowerCase();
          const searchLower = value.toLowerCase();
          
          if (aLower === searchLower) return -1;
          if (bLower === searchLower) return 1;
          if (aLower.startsWith(searchLower) && !bLower.startsWith(searchLower)) return -1;
          if (bLower.startsWith(searchLower) && !aLower.startsWith(searchLower)) return 1;
          
          // Prioritizează orașele principale
          const aIsMainCity = locations.cities.indexOf(a) < 10;
          const bIsMainCity = locations.cities.indexOf(b) < 10;
          if (aIsMainCity && !bIsMainCity) return -1;
          if (bIsMainCity && !aIsMainCity) return 1;
          
          return a.localeCompare(b);
        })
        .slice(0, 8);
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSelect = (location) => {
    setSearchTerm(getDisplayLocation(location));
    setSuggestions([]);
    setIsOpen(false);
    onSelect(location);
  };

  return (
    <div className="relative w-full" ref={wrapperRef}>
      <div className="relative">
        <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        <input
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          placeholder="Alege orașul..."
          className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>
      
      {isOpen && suggestions.length > 0 && (
        <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto">
          {suggestions.map((location, index) => (
            <li
              key={index}
              onClick={() => handleSelect(location)}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-gray-700"
            >
              {getDisplayLocation(location)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CityAutocomplete;

