'use client';

import React, { useEffect } from 'react';
import { AuthProvider } from '@/context/AuthContext';
import './globals.css';
import Navbar from '@/components/Navbar';
import { Analytics } from "@vercel/analytics/react";
import { GoogleTagManager } from '@next/third-parties/google';
import Footer from '@/components/Footer';
import PublicBanner from '@/components/layout/PublicBanner';
import Script from 'next/script';
import { initTikTokPixel } from '@/utils/analytics';
import ActionNav from '@/components/ActionNav';

export default function RootLayout({ children }) {
  useEffect(() => {
    // Initialize TikTok Pixel
    initTikTokPixel();
  }, []);

  return (
    <html lang="ro">
      <head>
        <Script
          id="tiktok-pixel"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              !function (w, d, t) {
                w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
                ttq.load('CJBQBOJC77U1UQBQM4N0');
                ttq.page();
              }(window, document, 'ttq');
            `,
          }}
        />
      </head>
      <body 
        className="min-h-screen bg-white antialiased"
        suppressHydrationWarning
      >
        <AuthProvider>
          <ActionNav />
          <Navbar />
          {/* <PublicBanner /> */}
          <div className="md:pt-20 pt-16 min-h-screen w-full md:pl-[4.3rem]">
            <main id="main-content" role="main" className="relative">
              {children}
            </main>
          </div>
          <Footer />
          <Analytics />
          <GoogleTagManager gtmId="GTM-P7LD6FBK" />
        </AuthProvider>
      </body>
    </html>
  );
}
