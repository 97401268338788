'use client';

import { useEffect, useState } from 'react';
import { supabase } from '@/lib/supabase';
import { Star, ExternalLink } from 'lucide-react';
import Link from 'next/link';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { Skeleton } from "@/components/ui/skeleton"
import { generateWorkerSlug } from '@/utils/slugs';

export default function TestimonialsSection() {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchReviews() {
      try {
        const { data, error } = await supabase
          .from('reviews')
          .select(`
            id,
            rating,
            comment,
            created_at,
            jobs (
              title,
              description
            ),
            client:client_id (
              id,
              name,
              avatar_url
            ),
            worker:worker_id (
              id,
              name,
              trade,
              address,
              avatar_url
            )
          `)
          .order('created_at', { ascending: false })
          .limit(6);

        if (error) {
          console.log('Query error:', error);
          throw error;
        }

        console.log('Fetched reviews:', data);
        setReviews(data);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchReviews();
  }, []);

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <Star
        key={index}
        className={`w-4 h-4 ${
          index < rating ? 'text-yellow-400 fill-yellow-400' : 'text-gray-300'
        }`}
      />
    ));
  };

  if (loading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {[...Array(6)].map((_, index) => (
          <Card key={index} className="w-full bg-white/10 backdrop-blur-lg border-white/10">
            <CardHeader>
              <Skeleton className="h-4 w-2/3 bg-white/5" />
              <Skeleton className="h-4 w-24 mt-2 bg-white/5" />
            </CardHeader>
            <CardContent>
              <Skeleton className="h-4 w-full mb-2 bg-white/5" />
              <Skeleton className="h-4 w-full mb-2 bg-white/5" />
              <Skeleton className="h-4 w-2/3 bg-white/5" />
            </CardContent>
            <CardFooter className="border-t border-white/10 pt-4">
              <div className="flex items-center w-full justify-between">
                <div className="flex items-center">
                  <Skeleton className="h-10 w-10 rounded-full bg-white/5" />
                  <div className="ml-3">
                    <Skeleton className="h-4 w-24 mb-2 bg-white/5" />
                    <Skeleton className="h-3 w-16 bg-white/5" />
                  </div>
                </div>
                <Skeleton className="h-9 w-28 bg-white/5" />
              </div>
            </CardFooter>
          </Card>
        ))}
      </div>
    );
  }

  return (
    <section className="py-16 md:py-24 bg-gradient-to-br from-white via-gray-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Experiențe Reale
          </h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Descoperă poveștile de succes ale clienților noștri și experiențele lor cu meseriașii de încredere
          </p>
        </div>

        <div className={`grid grid-cols-1 ${
          reviews.length === 1 ? 'md:grid-cols-1 max-w-lg' : 
          reviews.length === 2 ? 'md:grid-cols-2 max-w-3xl' : 
          'md:grid-cols-2 lg:grid-cols-3'
        } gap-6 lg:gap-8 mx-auto`}>
          {reviews.map((review) => (
            <Card 
              key={review.id} 
              className="group flex flex-col w-full bg-white rounded-xl shadow-md hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
            >
              <CardHeader className="pb-2">
                <div className="flex items-start justify-between mb-3">
                  <div className="flex items-center">
                    <Avatar className="h-10 w-10 border-2 border-white ring-2 ring-blue-50">
                      <AvatarImage 
                        src={review.client?.avatar_url} 
                        alt={review.client?.name || 'Avatar client'} 
                      />
                      <AvatarFallback className="bg-blue-50 text-blue-600 font-medium">
                        {review.client?.name?.charAt(0) || '?'}
                      </AvatarFallback>
                    </Avatar>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-900">
                        {review.client?.name || 'Client Anonim'}
                      </p>
                      <p className="text-xs text-gray-500">
                        {new Date(review.created_at).toLocaleDateString('ro-RO')}
                      </p>
                    </div>
                  </div>
                  <Badge variant="secondary" className="bg-blue-50 text-blue-600 font-medium">
                    {review.rating.toFixed(1)}
                  </Badge>
                </div>
                <CardTitle className="text-lg font-semibold text-gray-900 line-clamp-1 mb-2">
                  {review.jobs?.title}
                </CardTitle>
                <div className="flex">{renderStars(review.rating)}</div>
              </CardHeader>
              
              <CardContent className="flex-grow pt-2">
                <blockquote className="text-gray-600 text-sm leading-relaxed min-h-[80px] italic">
                  "{review.comment}"
                </blockquote>
              </CardContent>
              
              <CardFooter className="border-t border-gray-100 pt-4 mt-auto">
                {review.worker?.id && (
                  <Link 
                    href={`/meseriasi/${generateWorkerSlug(
                      review.worker.name,
                      review.worker.trade,
                      review.worker.address,
                      review.worker.id
                    )}`}
                    className="w-full inline-flex items-center justify-center px-4 py-2.5 text-sm font-medium
                             text-blue-600 bg-blue-50 rounded-lg hover:bg-blue-100 transition-colors group-hover:bg-blue-100"
                  >
                    Vezi meseriașul
                    <ExternalLink className="ml-2 h-4 w-4" />
                  </Link>
                )}
              </CardFooter>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
}