'use client';

import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, ChevronRight, ChevronLeft } from 'lucide-react';
import { getUserTutorialProgress, markTutorialAsCompleted } from '@/lib/tutorials';

export default function TutorialDialog({ 
  tutorials, 
  userId, 
  onComplete, 
  section,
  userType,
  onClose 
}) {
  const [currentStep, setCurrentStep] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const currentTutorial = tutorials[currentStep];

  const handleNext = async () => {
    // Mark current tutorial as completed
    await markTutorialAsCompleted(userId, currentTutorial.id);

    if (currentStep === tutorials.length - 1) {
      setIsVisible(false);
      onComplete?.();
    } else {
      setCurrentStep(prev => prev + 1);
    }
  };

  const handlePrevious = () => {
    setCurrentStep(prev => Math.max(0, prev - 1));
  };

  const handleSkip = async () => {
    // Mark all remaining tutorials as skipped/completed
    for (let i = currentStep; i < tutorials.length; i++) {
      await markTutorialAsCompleted(userId, tutorials[i].id);
    }
    setIsVisible(false);
    onClose?.();
  };

  if (!isVisible || !currentTutorial) return null;

  return (
    <AnimatePresence>
      <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          className="bg-gray-800 rounded-xl shadow-lg max-w-md w-full mx-4 overflow-hidden border border-white/10"
        >
          {/* Header */}
          <div className="p-4 border-b border-white/10 flex items-center justify-between">
            <h3 className="text-lg font-semibold text-white">
              {currentTutorial.title}
            </h3>
            <button
              onClick={handleSkip}
              className="text-gray-400 hover:text-white transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          {/* Content */}
          <div className="p-6">
            <p className="text-gray-300">
              {currentTutorial.content}
            </p>
          </div>

          {/* Footer */}
          <div className="p-4 border-t border-white/10 flex items-center justify-between">
            <div className="flex items-center gap-2">
              {tutorials.map((_, index) => (
                <div
                  key={index}
                  className={`w-2 h-2 rounded-full transition-colors ${
                    index === currentStep
                      ? 'bg-[#f0ff5a]'
                      : 'bg-white/20'
                  }`}
                />
              ))}
            </div>

            <div className="flex items-center gap-3">
              {currentStep > 0 && (
                <button
                  onClick={handlePrevious}
                  className="flex items-center gap-1 px-3 py-1.5 text-sm text-white/80 hover:text-white 
                           transition-colors"
                >
                  <ChevronLeft className="w-4 h-4" />
                  Înapoi
                </button>
              )}
              <button
                onClick={handleNext}
                className="flex items-center gap-1 px-4 py-2 bg-[#f0ff5a] text-gray-900 rounded-lg 
                         hover:bg-[#f0ff5a]/90 transition-colors text-sm font-medium"
              >
                {currentStep === tutorials.length - 1 ? 'Finalizează' : 'Următorul'}
                <ChevronRight className="w-4 h-4" />
              </button>
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
} 