import React from 'react';
import Link from 'next/link';
import { FaEnvelope, FaFacebook, FaInstagram, FaLinkedin, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';
import { FaXTwitter, FaTiktok } from 'react-icons/fa6';

const Footer = () => {
  return (
    <footer className="relative overflow-hidden bg-gray-900 text-white">
      <div className="absolute inset-0 bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]"></div>
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 lg:py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 lg:gap-12">
          {/* About Us */}
          <div className="space-y-4">
            <h4 className="text-2xl font-bold mb-4">Meserias Local</h4>
            <p className="text-gray-300 leading-relaxed">
              Platforma ta de încredere pentru a găsi profesioniști verificați în diverse domenii. Oferim soluții rapide și eficiente pentru nevoile tale.
            </p>
            <div className="pt-4">
              <Link href="/despre" className="inline-flex items-center text-gray-200 hover:text-white transition-colors duration-300">
                Află mai multe despre noi
                <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg>
              </Link>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h4 className="text-xl font-semibold mb-4">Linkuri Rapide</h4>
            <ul className="space-y-2">
              {['Servicii', 'Despre Noi', 'Contact', 'Termeni si Conditii', 'Politica de Confidentialitate'].map((item) => (
                <li key={item}>
                  <Link href={`/${item.toLowerCase().replace(/\s+/g, '-')}`} className="text-gray-300 hover:text-white transition-colors duration-300 flex items-center">
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg>
                    {item}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h4 className="text-xl font-semibold mb-4">Contact</h4>
            <ul className="space-y-4">
              <li className="flex items-start">
                <FaMapMarkerAlt className="w-5 h-5 text-gray-200 mt-1 mr-3" />
                <span className="text-gray-300">Strada Mesteacanului, 3, Suceava, România</span>
              </li>
              <li className="flex items-center">
                <FaPhone className="w-5 h-5 text-gray-200 mr-3" />
                <span className="text-gray-300">+447930097259</span>
              </li>
              <li className="flex items-center">
                <FaEnvelope className="w-5 h-5 text-gray-200 mr-3" />
                <span className="text-gray-300">contact@meseriaslocal.ro</span>
              </li>
            </ul>
          </div>

          {/* Newsletter */}
          <div>
            <h4 className="text-xl font-semibold mb-4">Abonează-te la Newsletter</h4>
            <p className="text-gray-300 mb-4">Primește cele mai noi oferte și actualizări direct în inbox-ul tău.</p>
            <form className="space-y-2">
              <input
                type="email"
                placeholder="Adresa ta de email"
                className="w-full px-4 py-2 rounded-md bg-gray-800 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-200"
              />
              <button
                type="submit"
                className="w-full px-4 py-2 bg-gray-200 text-gray-900 rounded-md hover:bg-gray-300 transition-colors duration-300"
              >
                Abonează-te
              </button>
            </form>
          </div>
        </div>

        {/* Social Media and Copyright */}
        <div className="mt-12 pt-8 border-t border-gray-800 flex flex-col md:flex-row justify-between items-center">
          <div className="flex space-x-6 mb-4 md:mb-0">
            <Link href="https://www.facebook.com/localmeserias" target="_blank" rel="noopener noreferrer" className="text-gray-200 hover:text-white transition-colors duration-300">
              <FaFacebook size={24} />
            </Link>
            <Link href="https://www.instagram.com/meseriaslocal/" target="_blank" rel="noopener noreferrer" className="text-gray-200 hover:text-white transition-colors duration-300">
              <FaInstagram size={24} />
            </Link>
            <Link href="https://x.com/meseriaslocal" target="_blank" rel="noopener noreferrer" className="text-gray-200 hover:text-white transition-colors duration-300">
              <FaXTwitter size={24} />
            </Link>
            <Link href="https://www.tiktok.com/@meseriaslocal.ro" target="_blank" rel="noopener noreferrer" className="text-gray-200 hover:text-white transition-colors duration-300">
              <FaTiktok size={24} />
            </Link>
          </div>
          <div className="text-gray-300 text-sm">
            &copy; {new Date().getFullYear()} Meserias Local. Toate drepturile rezervate.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;