'use client'
import Image from 'next/image';
import ClientHeroForm from './ClientHeroForm';

export default function NewHero() {
  return (
    <div className="relative min-h-screen flex flex-col overflow-hidden">
      {/* Background */}
      <div className="absolute inset-0">
        <Image
          src="/hero/BG.webp"
          alt="Background"
          fill
          className="object-cover"
          priority
        />
        <div className="absolute inset-0 bg-gradient-to-br from-black/90 via-black/70 to-black/40 backdrop-blur-sm" />
        <div className="absolute inset-0 bg-[url('/hero/grid.svg')] opacity-20" />
      </div>

      {/* Main Content */}
      <div className="relative flex-1 max-w-[1400px] mx-auto w-full px-4 lg:px-8 py-12 lg:py-0">
        {/* Mobile: Stacked Layout */}
        <div className="flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-8">
          {/* Text Content */}
          <div className="w-full lg:w-[60%] z-10 text-center lg:text-left">
            <div className="space-y-6 max-w-2xl mx-auto lg:mx-0">
              {/* Platform Badge */}
              <div className="inline-block">
                <span className="px-4 py-1 rounded-full bg-white/10 backdrop-blur-md text-xs sm:text-sm font-medium text-white border border-white/10">
                  🏗️ Platforma Nr. 1 pentru Meseriași în România
                </span>
              </div>

              {/* Headings */}
              <h1 className="text-3xl sm:text-5xl lg:text-6xl font-bold leading-tight">
                <span className="text-white block">Meseriași de Încredere</span>
                <span className="bg-gradient-to-r from-blue-400 to-emerald-400 text-transparent bg-clip-text"> la un Click Distanță</span>
              </h1>

              <p className="text-base sm:text-xl text-gray-300 leading-relaxed">
                Conectează-te cu profesioniști verificați și transformă-ți ideile în realitate, rapid și sigur!
              </p>

              {/* Hero Form */}
              <div className="backdrop-blur-md bg-white/5 p-1.5 sm:p-2 rounded-2xl border border-white/10">
                <ClientHeroForm />
              </div>

              {/* Features Grid
              <div className="grid grid-cols-3 gap-3 sm:gap-6 pt-6 sm:pt-8">
                {[
                  {
                    icon: "/hero/Verified.svg",
                    title: "Profesioniști Verificați"
                  },
                  {
                    icon: "/hero/Fast.svg",
                    title: "Servicii Imediate"
                  },
                  {
                    icon: "/hero/Safe.svg",
                    title: "Siguranță Garantată"
                  }
                ].map((feature, index) => (
                  <div
                    key={index}
                    className="group flex flex-col items-center text-center p-2 sm:p-4 rounded-xl 
                    backdrop-blur-md bg-white/5 border border-white/10 
                    hover:bg-white/10 transition-all duration-300 
                    transform hover:scale-105"
                  >
                    <div className="bg-gradient-to-br from-blue-500/20 to-emerald-500/20 p-1.5 sm:p-2 rounded-lg mb-1 sm:mb-2">
                      <Image
                        src={feature.icon}
                        alt={feature.title}
                        width={24}
                        height={24}
                        className="w-4 h-4 sm:w-6 sm:h-6"
                      />
                    </div>
                    <span className="text-xs sm:text-sm font-medium text-white mb-0.5">
                      {feature.title}
                    </span>
                  </div>
                ))}
              </div> */}
            </div>
          </div>

          {/* Image Section */}
          <div className="w-full lg:w-[40%] relative h-[300px] sm:h-[400px] lg:h-[90vh]">
            <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-transparent lg:hidden" />
            <Image
              src="/hero/meserias.webp"
              alt="Professional Worker"
              fill
              className="object-contain object-bottom rounded-2xl shadow-2xl"
              priority
            />
          </div>
        </div>
      </div>
    </div>
  );
}