import { supabase } from './supabase';

// Notification Types
export const NotificationTypes = {
  MESSAGE: 'message',
  JOB_UPDATE: 'job_update',
  REVIEW: 'review',
  PAYMENT: 'payment',
  DEADLINE: 'deadline',
  PROFILE_VIEW: 'profile_view',
  PROMOTION: 'promotion',
  ALERT: 'alert'
};

// Smart notification content generators
const notificationTemplates = {
  [NotificationTypes.MESSAGE]: (data) => ({
    content: `Ai un mesaj nou de la ${data.senderName} pentru proiectul "${data.jobTitle}"`,
    priority: 'high'
  }),
  [NotificationTypes.JOB_UPDATE]: (data) => {
    console.log('Creating job update notification with data:', data);
    if (data.status === 'applied') {
      return {
        content: `Ai aplicat cu succes la jobul "${data.jobTitle}"`,
        priority: 'high'
      };
    }
    if (data.status === 'pending' && data.workerName) {
      return {
        content: `${data.workerName} a aplicat la jobul "${data.jobTitle}"`,
        priority: 'high'
      };
    }
    const statusMessages = {
      pending: 'este în așteptare',
      in_progress: 'a început',
      completed: 'a fost finalizat',
      cancelled: 'a fost anulat'
    };
    return {
      content: `Proiectul "${data.jobTitle}" ${statusMessages[data.status] || 'a fost actualizat'}`,
      priority: data.status === 'completed' ? 'high' : 'medium'
    };
  },
  [NotificationTypes.REVIEW]: (data) => ({
    content: `${data.reviewerName} ți-a lăsat un review ${data.rating ? `de ${data.rating} stele` : ''} pentru proiectul "${data.jobTitle}"`,
    priority: 'high'
  }),
  [NotificationTypes.PAYMENT]: (data) => ({
    content: `Plata de ${data.amount} RON pentru proiectul "${data.jobTitle}" ${data.status === 'success' ? 'a fost procesată cu succes' : 'așteaptă confirmarea'}`,
    priority: 'high'
  }),
  [NotificationTypes.DEADLINE]: (data) => {
    const daysLeft = Math.ceil((new Date(data.deadline) - new Date()) / (1000 * 60 * 60 * 24));
    return {
      content: `Mai ai ${daysLeft} ${daysLeft === 1 ? 'zi' : 'zile'} până la termenul limită pentru proiectul "${data.jobTitle}"`,
      priority: daysLeft <= 2 ? 'high' : 'medium'
    };
  },
  [NotificationTypes.PROFILE_VIEW]: (data) => ({
    content: `${data.viewerName || 'Cineva'} ți-a vizualizat profilul`,
    priority: 'low'
  }),
  [NotificationTypes.PROMOTION]: (data) => ({
    content: data.content,
    priority: 'medium'
  }),
  [NotificationTypes.ALERT]: (data) => ({
    content: data.content,
    priority: data.priority || 'medium'
  })
};

// Helper function to group similar notifications
const groupSimilarNotifications = (notifications) => {
  const groups = {};
  
  notifications.forEach(notification => {
    const key = `${notification.type}_${notification.job_id || 'general'}`;
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(notification);
  });

  return Object.values(groups).map(group => {
    if (group.length === 1) return group[0];

    // Create a grouped notification
    const first = group[0];
    return {
      ...first,
      content: `Ai ${group.length} notificări noi de tipul ${first.type.replace('_', ' ')}`,
      grouped: true,
      group: group
    };
  });
};

// Create a notification with smart content generation
export async function createNotification(userId, type, content, jobId) {
  console.log('Creating notification:', { userId, type, content, jobId });
  
  const notification = {
    user_id: userId,
    type,
    content,
    job_id: jobId,
    is_read: false,
    created_at: new Date().toISOString()
  };

  const { data, error } = await supabase
    .from('notifications')
    .insert([notification])
    .select();

  if (error) {
    console.error('Error creating notification:', error);
    throw error;
  }

  console.log('Notification created:', data);
  return data;
}

// Get notifications with smart grouping and sorting
export async function getNotifications(userId, options = {}) {
  const {
    limit = 20,
    includeRead = false,
    groupSimilar = true,
    type = null
  } = options;

  try {
    let query = supabase
      .from('notifications')
      .select('*')
      .eq('user_id', userId)
      .order('created_at', { ascending: false });

    if (!includeRead) {
      query = query.eq('is_read', false);
    }

    if (type) {
      query = query.eq('type', type);
    }

    const { data: notifications, error } = await query.limit(limit);

    if (error) throw error;

    // Apply grouping if requested
    const processedNotifications = groupSimilar 
      ? groupSimilarNotifications(notifications || [])
      : notifications || [];

    // Sort by priority and date
    return processedNotifications.sort((a, b) => {
      const priorityOrder = { high: 3, medium: 2, low: 1 };
      const priorityDiff = priorityOrder[b.priority] - priorityOrder[a.priority];
      if (priorityDiff !== 0) return priorityDiff;
      return new Date(b.created_at) - new Date(a.created_at);
    });

  } catch (error) {
    console.error('Error fetching notifications:', error);
    return [];
  }
}

// Mark notification(s) as read
export async function markNotificationsAsRead(notificationIds, userId) {
  try {
    const { error } = await supabase
      .from('notifications')
      .update({ 
        is_read: true,
        updated_at: new Date().toISOString()
      })
      .in('id', Array.isArray(notificationIds) ? notificationIds : [notificationIds])
      .eq('user_id', userId);

    if (error) throw error;
    return true;
  } catch (error) {
    console.error('Error marking notifications as read:', error);
    return false;
  }
}

// Delete old notifications
export async function cleanupOldNotifications(userId, daysToKeep = 30) {
  try {
    const cutoffDate = new Date();
    cutoffDate.setDate(cutoffDate.getDate() - daysToKeep);

    const { error } = await supabase
      .from('notifications')
      .delete()
      .eq('user_id', userId)
      .eq('is_read', true)
      .lt('created_at', cutoffDate.toISOString());

    if (error) throw error;
    return true;
  } catch (error) {
    console.error('Error cleaning up old notifications:', error);
    return false;
  }
}

// Get unread notifications count
export async function getUnreadNotificationsCount(userId) {
  try {
    const { count, error } = await supabase
      .from('notifications')
      .select('*', { count: 'exact', head: true })
      .eq('user_id', userId)
      .eq('is_read', false);

    if (error) throw error;
    return count || 0;
  } catch (error) {
    console.error('Error getting unread notifications count:', error);
    return 0;
  }
}

// Subscribe to notifications (for real-time updates)
export function subscribeToNotifications(userId, callback) {
  return supabase
    .channel(`public:notifications:user_id=eq.${userId}`)
    .on('postgres_changes', {
      event: '*',
      schema: 'public',
      table: 'notifications',
      filter: `user_id=eq.${userId}`
    }, payload => {
      callback(payload);
    })
    .subscribe();
}

export const notificationTypes = {
  message: 'Mesaje',
  job_update: 'Actualizări lucrări',
  review: 'Review-uri',
  payment: 'Plăți',
  deadline: 'Termene limită'
};
