import { supabase } from './supabase';

// Tutorial steps for different user types and sections
export const tutorialSteps = {
  worker: {
    profile: [
      {
        id: 'profile_photo',
        title: 'Adaugă o poză de profil',
        content: 'O poză de profil profesională crește șansele de a primi lucrări cu 70%.',
        target: '.profile-photo',
        placement: 'right',
      },
      {
        id: 'skills',
        title: 'Adaugă-ți abilitățile',
        content: 'Specifică domeniile în care ai experiență pentru a primi lucrări relevante.',
        target: '.skills-section',
        placement: 'bottom',
      },
      {
        id: 'portfolio',
        title: 'Încarcă poze cu lucrările tale',
        content: 'Clienții sunt de 3 ori mai înclinați să aleagă meseriași cu portofoliu.',
        target: '.portfolio-section',
        placement: 'left',
      }
    ],
    dashboard: [
      {
        id: 'worker_stats',
        title: 'Statisticile tale',
        content: 'Aici poți vedea câte lucrări ai finalizat și rating-ul tău general.',
        target: '.stats-section',
        placement: 'bottom',
      },
      {
        id: 'active_jobs',
        title: 'Lucrări active',
        content: 'Vezi lucrările la care lucrezi în prezent și statusul lor.',
        target: '.active-jobs',
        placement: 'bottom',
      },
      {
        id: 'new_jobs',
        title: 'Lucrări noi',
        content: 'Descoperă cele mai recente oportunități de lucru din zona ta.',
        target: '.new-jobs',
        placement: 'left',
      },
      {
        id: 'earnings',
        title: 'Câștigurile tale',
        content: 'Urmărește-ți veniturile și istoricul plăților.',
        target: '.earnings-section',
        placement: 'right',
      },
      {
        id: 'notifications_worker',
        title: 'Notificări',
        content: 'Fii la curent cu mesajele de la clienți și actualizările lucrărilor.',
        target: '.notifications-section',
        placement: 'left',
      }
    ],
    jobs: [
      {
        id: 'job_search',
        title: 'Caută lucrări',
        content: 'Folosește filtrele pentru a găsi lucrări în zona ta și în domeniile tale de expertiză.',
        target: '.job-filters',
        placement: 'bottom',
      },
      {
        id: 'job_apply',
        title: 'Aplică la lucrări',
        content: 'Trimite o ofertă personalizată și specifică disponibilitatea ta.',
        target: '.apply-button',
        placement: 'left',
      }
    ],
    messages: [
      {
        id: 'chat_etiquette',
        title: 'Comunicare profesională',
        content: 'Menține o comunicare clară și profesională cu clienții pentru a crește șansele de colaborare.',
        target: '.chat-input',
        placement: 'top',
      }
    ]
  },
  client: {
    profile: [
      {
        id: 'client_profile',
        title: 'Completează-ți profilul',
        content: 'Un profil complet ajută meseriașii să înțeleagă mai bine cu cine vor lucra.',
        target: '.profile-section',
        placement: 'right',
      }
    ],
    dashboard: [
      {
        id: 'post_new_job',
        title: 'Postează o lucrare nouă',
        content: 'Începe prin a posta o lucrare nouă pentru a găsi meseriașul potrivit.',
        target: '.post-job-button',
        placement: 'bottom',
      },
      {
        id: 'active_projects',
        title: 'Proiecte active',
        content: 'Urmărește progresul lucrărilor tale în desfășurare.',
        target: '.active-projects',
        placement: 'bottom',
      },
      {
        id: 'pending_offers',
        title: 'Oferte primite',
        content: 'Vezi și evaluează ofertele primite de la meseriași.',
        target: '.pending-offers',
        placement: 'left',
      },
      {
        id: 'messages_overview',
        title: 'Mesaje',
        content: 'Comunică cu meseriașii și păstrează toate conversațiile organizate.',
        target: '.messages-section',
        placement: 'right',
      },
      {
        id: 'notifications_client',
        title: 'Notificări',
        content: 'Primește actualizări despre oferte noi și progresul lucrărilor.',
        target: '.notifications-section',
        placement: 'left',
      }
    ],
    post_job: [
      {
        id: 'job_title',
        title: 'Titlu atractiv',
        content: 'Folosește un titlu clar care descrie exact ce ai nevoie.',
        target: '.job-title-input',
        placement: 'bottom',
      },
      {
        id: 'job_description',
        title: 'Descriere detaliată',
        content: 'Include toate detaliile importante: dimensiuni, materiale, termene, etc.',
        target: '.job-description',
        placement: 'right',
      },
      {
        id: 'job_budget',
        title: 'Buget realist',
        content: 'Stabilește un buget realist pentru a primi oferte serioase.',
        target: '.budget-input',
        placement: 'left',
      }
    ],
    hire: [
      {
        id: 'review_profile',
        title: 'Verifică profilul',
        content: 'Analizează portofoliul și review-urile înainte de a alege un meseriaș.',
        target: '.worker-profile',
        placement: 'right',
      }
    ]
  }
};

// Function to check if a user has completed specific tutorials
export async function getUserTutorialProgress(userId) {
  if (!userId) return [];
  
  try {
    // First, try to get existing record
    const { data, error } = await supabase
      .from('user_tutorials')
      .select('completed_tutorials')
      .eq('user_id', userId)
      .order('updated_at', { ascending: false })
      .limit(1)
      .maybeSingle();

    if (error) throw error;

    if (!data) {
      // No record found, create one
      const { data: newData, error: insertError } = await supabase
        .from('user_tutorials')
        .insert([{ 
          user_id: userId,
          completed_tutorials: []
        }])
        .select('completed_tutorials')
        .single();
        
      if (insertError) throw insertError;
      return newData?.completed_tutorials || [];
    }

    return data?.completed_tutorials || [];
  } catch (error) {
    console.error('Error fetching tutorial progress:', error);
    return [];
  }
}

// Function to mark a tutorial step as completed
export async function markTutorialAsCompleted(userId, tutorialId) {
  if (!userId || !tutorialId) return false;

  try {
    // Get current progress first
    const { data: existingData, error: fetchError } = await supabase
      .from('user_tutorials')
      .select('completed_tutorials')
      .eq('user_id', userId)
      .order('updated_at', { ascending: false })
      .limit(1)
      .maybeSingle();

    if (fetchError) throw fetchError;

    // Initialize completed tutorials array
    let completedTutorials = existingData?.completed_tutorials || [];
    
    // Add new tutorial if not already completed
    if (!completedTutorials.includes(tutorialId)) {
      completedTutorials = [...completedTutorials, tutorialId];
      
      // Delete old records first
      await supabase
        .from('user_tutorials')
        .delete()
        .eq('user_id', userId);
      
      // Insert new record
      const { error: insertError } = await supabase
        .from('user_tutorials')
        .insert({
          user_id: userId,
          completed_tutorials: completedTutorials,
          updated_at: new Date().toISOString()
        });

      if (insertError) throw insertError;
    }
    
    return true;
  } catch (error) {
    console.error('Error marking tutorial as completed:', error);
    return false;
  }
}

// Function to reset tutorial progress
export async function resetTutorials(userId) {
  try {
    // Delete old records first
    await supabase
      .from('user_tutorials')
      .delete()
      .eq('user_id', userId);
    
    // Insert new record
    const { error } = await supabase
      .from('user_tutorials')
      .insert({
        user_id: userId,
        completed_tutorials: [],
        updated_at: new Date().toISOString()
      });

    if (error) throw error;
    return true;
  } catch (error) {
    console.error('Error resetting tutorials:', error);
    return false;
  }
}

// Function to get relevant tutorials for current page/section
export function getRelevantTutorials(userType, section, completedTutorials = []) {
  const sectionTutorials = tutorialSteps[userType]?.[section] || [];
  return sectionTutorials.filter(tutorial => !completedTutorials.includes(tutorial.id));
} 