'use client';

import { useAuth } from '@/context/AuthContext';
import { useRouter } from 'next/navigation';
import { motion, AnimatePresence } from 'framer-motion';
import { Wrench, ArrowRight, X, Plus } from 'lucide-react';
import { useState, useEffect } from 'react';

const bannerData = {
  worker: {
    title: "Devino Meseriaș PRO",
    ctaText: "Înregistrează-te Gratuit",
    ctaLink: "/register/worker",
    icon: Wrench,
    style: "bg-gradient-to-r from-[#f0ff5a] to-yellow-300 text-gray-900 hover:from-yellow-300 hover:to-[#f0ff5a]",
    progressColor: "bg-[#f0ff5a]"
  },
  client: {
    title: "Găsește Meseriași Verificați",
    ctaText: "Postează Lucrare",
    ctaLink: "/new-post",
    icon: Plus,
    style: "bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:from-blue-600 hover:to-blue-500",
    progressColor: "bg-blue-500"
  }
};

export default function PublicBanner() {
  const { user } = useAuth();
  const router = useRouter();
  const [isVisible, setIsVisible] = useState(false);
  const [currentBanner, setCurrentBanner] = useState('worker');
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const bannerDismissed = localStorage.getItem('bannerDismissed');
    if (!bannerDismissed) {
      setIsVisible(true);
    }

    // Change banner every 5 seconds
    const bannerInterval = setInterval(() => {
      setCurrentBanner(current => current === 'worker' ? 'client' : 'worker');
      setProgress(0);
    }, 5000);

    // Update progress bar every 50ms
    const progressInterval = setInterval(() => {
      setProgress(prev => Math.min(prev + 1, 100));
    }, 50);

    return () => {
      clearInterval(bannerInterval);
      clearInterval(progressInterval);
    };
  }, []);

  const handleDismiss = () => {
    setIsVisible(false);
    localStorage.setItem('bannerDismissed', 'true');
  };

  if (user || !isVisible) return null;

  const banner = bannerData[currentBanner];
  const Icon = banner.icon;

  return (
    <div className="bg-gray-900 shadow-lg relative py-2.5">
      {/* Progress Bar cu culoare dinamică */}
      <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-gray-700">
        <motion.div 
          className={`h-full ${banner.progressColor}`}
          animate={{ width: `${progress}%` }}
          transition={{ duration: 5, ease: "linear" }}
        />
      </div>

      <div className="max-w-7xl mx-auto px-4 flex items-center justify-between gap-4">
        {/* Title */}
        <h3 className="text-white font-bold text-sm md:text-lg whitespace-nowrap">
          {banner.title}
        </h3>

        {/* CTA Button */}
        <div className="flex items-center gap-3">
          <button
            onClick={() => router.push(banner.ctaLink)}
            className={`flex items-center justify-center gap-2 px-3 py-1.5 
              rounded-lg font-medium transition-all 
              text-xs md:text-sm whitespace-nowrap hover:scale-105 active:scale-95
              ${banner.style}`}
          >
            <Icon className="w-4 h-4" />
            <span>{banner.ctaText}</span>
            <ArrowRight className="w-4 h-4" />
          </button>

          <button
            onClick={handleDismiss}
            className="p-1.5 text-gray-400 hover:text-white transition-colors rounded-full hover:bg-gray-700"
            aria-label="Închide banner"
          >
            <X className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
}