export function generateWorkerSlug(name, trade, address, id) {
  if (!name || !trade || !id) return '';
  
  // Funcție helper pentru normalizarea textului
  const normalize = (text) => {
    return text
      .toLowerCase()
      // Înlocuim diacriticele
      .replace(/ă/g, 'a')
      .replace(/â/g, 'a')
      .replace(/î/g, 'i')
      .replace(/ș/g, 's')
      .replace(/ț/g, 't')
      .replace(/[^a-z0-9\s]/g, '')
      .trim()
      .replace(/\s+/g, '-');
  };
  
  const normalizedName = normalize(name);
  const normalizedTrade = normalize(trade);
  const normalizedAddress = address ? normalize(address) : '';

  // Luăm doar ultimele 4 caractere din UUID
  const shortId = id.slice(-4);

  return `${normalizedName}-${normalizedTrade}-${normalizedAddress}-${shortId}`;
}

export function extractDataFromSlug(slug) {
  if (!slug) return null;
  
  const parts = slug.split('-');
  if (parts.length < 4) return null;

  const shortId = parts.pop();
  const address = parts.pop();
  const trade = parts.pop();
  const name = parts.join('-');

  return { name, trade, address, shortId };
} 