import React, { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';
import { useAuth } from '@/context/AuthContext';

const UnreadMessagesCount = ({ setUnreadMessagesCount }) => {
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      fetchUnreadCount();

      // Get jobs where user is client
      const getClientJobs = async () => {
        const { data: userJobs } = await supabase
          .from('jobs')
          .select('id')
          .eq('client_id', user.id);
        return userJobs?.map(job => job.id) || [];
      };

      // Set up subscriptions
      const setupSubscriptions = async () => {
        const jobIds = await getClientJobs();
        const workerFilter = `worker_id=eq.${user.id}`;
        const jobFilter = jobIds.length > 0 ? `job_id=in.(${jobIds.join(',')})` : null;
        const filter = jobFilter ? `${workerFilter},${jobFilter}` : workerFilter;

        const channel = supabase
          .channel('unread_messages')
          .on('postgres_changes', { 
            event: 'INSERT', 
            schema: 'public', 
            table: 'messages',
            filter: filter
          }, () => {
            fetchUnreadCount();
          })
          .on('postgres_changes', { 
            event: 'UPDATE', 
            schema: 'public', 
            table: 'messages',
            filter: filter
          }, () => {
            fetchUnreadCount();
          })
          .subscribe();

        return () => {
          supabase.removeChannel(channel);
        };
      };

      const cleanup = setupSubscriptions();
      return () => {
        cleanup.then(cleanupFn => cleanupFn());
      };
    }
  }, [user]);

  const fetchUnreadCount = async () => {
    if (!user) {
      setUnreadMessagesCount(0);
      return;
    }

    try {
      // First get jobs where user is client
      const { data: userJobs } = await supabase
        .from('jobs')
        .select('id')
        .eq('client_id', user.id);

      const jobIds = userJobs?.map(job => job.id) || [];

      const { count, error } = await supabase
        .from('messages')
        .select('id', { count: 'exact' })
        .eq('is_read', false)
        .neq('sender_id', user.id)
        .or(jobIds.length > 0 
          ? `worker_id.eq.${user.id},job_id.in.(${jobIds.join(',')})`
          : `worker_id.eq.${user.id}`
        );

      if (error) {
        console.error('Error fetching unread count:', error.message || error);
        setUnreadMessagesCount(0);
        return;
      }

      setUnreadMessagesCount(count || 0);
    } catch (error) {
      console.error('Error in fetchUnreadCount:', error.message || error);
      setUnreadMessagesCount(0);
    }
  };

  const resetUnreadCount = async () => {
    if (!user) return;

    try {
      // First get jobs where user is client
      const { data: userJobs } = await supabase
        .from('jobs')
        .select('id')
        .eq('client_id', user.id);

      const jobIds = userJobs?.map(job => job.id) || [];

      const { error } = await supabase
        .from('messages')
        .update({ is_read: true })
        .eq('is_read', false)
        .neq('sender_id', user.id)
        .or(jobIds.length > 0 
          ? `worker_id.eq.${user.id},job_id.in.(${jobIds.join(',')})`
          : `worker_id.eq.${user.id}`
        );

      if (error) {
        console.error('Error resetting unread count:', error.message || error);
        return;
      }

      setUnreadMessagesCount(0);
    } catch (error) {
      console.error('Error in resetUnreadCount:', error.message || error);
    }
  };

  return null;
};

export default UnreadMessagesCount;
