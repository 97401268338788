'use client'

import { useRouter } from 'next/navigation'
import { UserPlus, Briefcase, ArrowRight, LogIn, Home, Bell, User, Settings, Search } from 'lucide-react'
import Image from 'next/image'
import Link from 'next/link'
import { useAuth } from '@/context/AuthContext'
import { useState, useEffect } from 'react'
import { supabase } from '@/lib/supabase'

export default function ActionNav() {
  const router = useRouter()
  const { user, signOut } = useAuth()
  const [unreadCount, setUnreadCount] = useState(0)

  useEffect(() => {
    if (user) {
      const fetchUnreadNotifications = async () => {
        const { data, error } = await supabase
          .from('notifications')
          .select('count', { count: 'exact' })
          .eq('user_id', user.id)
          .eq('read', false)

        if (!error && data) {
          setUnreadCount(data.length)
        }
      }

      fetchUnreadNotifications()

      // Subscribe to changes
      const channel = supabase
        .channel('notifications')
        .on('postgres_changes', 
          { 
            event: '*', 
            schema: 'public', 
            table: 'notifications',
            filter: `user_id=eq.${user.id}`
          }, 
          () => {
            fetchUnreadNotifications()
          }
        )
        .subscribe()

      return () => {
        channel.unsubscribe()
      }
    }
  }, [user])

  const handleLogout = async () => {
    try {
      await signOut()
      router.push('/')
    } catch (error) {
      console.error('Error signing out:', error)
    }
  }

  return (
    <>
      {/* Mobile Navigation */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-gray-900 border-b border-gray-800 md:hidden">
        <div className="px-4 h-16 flex items-center justify-between">
          {/* Logo for Mobile */}
          <Link href="/" className="flex items-center gap-2">
            <Image
              src="/logo.svg"
              alt="Logo"
              width={140}
              height={140}
              className="w-auto h-10"
              priority
            />
            <span className="font-semibold text-lg">
              <span className="text-white">Meserias</span>{' '}
              <span className="text-orange-500">Local</span>
            </span>
          </Link>

          {/* Action Button for Mobile */}
          <button
            onClick={() => router.push('/meseriasi')}
            className="flex items-center gap-1.5 py-2 px-3 text-white bg-orange-500 hover:bg-orange-600
              rounded-lg transition-all duration-200 text-sm font-medium shadow-lg"
          >
            <Search className="w-4 h-4" />
            <span>Verifică Meseriași</span>
          </button>
        </div>
      </div>

      {/* Desktop Navigation */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-gray-900 border-b border-gray-800 hidden md:block">
        <div className="max-w-7xl mx-auto px-4 h-20 flex items-center justify-between">
          {/* Logo and Text */}
          <Link href="/" className="flex items-center gap-3">
            <Image
              src="/logo.svg"
              alt="Logo"
              width={180}
              height={180}
              className="w-auto h-14"
              priority
            />
            <span className="font-semibold text-xl">
              <span className="text-white">Meserias</span>{' '}
              <span className="text-yellow-500">Local</span>
            </span>
          </Link>

          {/* Action Buttons */}
          <div className="flex items-center gap-3">
            {!user ? (
              <>
                <button
                  onClick={() => router.push('/register/worker')}
                  className="flex items-center gap-2 py-2.5 px-4 text-white bg-orange-600 hover:bg-orange-700
                    rounded-xl transition-all duration-200 font-medium"
                >
                  <UserPlus className="w-5 h-5" />
                  <span>Devino Meseriaș</span>
                </button>

                <div className="w-px h-8 bg-gray-700"></div>

                <button
                  onClick={() => router.push('/new-post')}
                  className="flex items-center gap-2 py-2.5 px-4 text-white bg-blue-600 hover:bg-blue-700
                    rounded-xl transition-all duration-200 font-medium"
                >
                  <Briefcase className="w-5 h-5" />
                  <span>Cere Oferte</span>
                </button>

                <div className="w-px h-8 bg-gray-700"></div>

                <button
                  onClick={() => router.push('/login')}
                  className="flex items-center gap-2 py-2.5 px-4 text-gray-300 hover:text-white hover:bg-gray-800 
                    rounded-xl transition-all duration-200 font-medium"
                >
                  <LogIn className="w-5 h-5" />
                  <span>Autentificare</span>
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => router.push(user.role === 'worker' ? '/dashboard/worker' : '/dashboard/client')}
                  className="flex items-center gap-2 py-2.5 px-4 text-white bg-orange-600 hover:bg-blue-700
                    rounded-xl transition-all duration-200 font-medium"
                >
                  <Home className="w-5 h-5" />
                  <span>Dashboard</span>
                </button>

                <div className="w-px h-8 bg-gray-700"></div>

                {user.role === 'client' && (
                  <>
                    <button
                      onClick={() => router.push('/post-job')}
                      className="flex items-center gap-2 py-2.5 px-4 text-white bg-orange-600 hover:bg-orange-700
                        rounded-xl transition-all duration-200 font-medium"
                    >
                      <Briefcase className="w-5 h-5" />
                      <span>Postează Job</span>
                    </button>
                    <div className="w-px h-8 bg-gray-700"></div>
                  </>
                )}
                
                {user.role === 'worker' && (
                  <>
                    <button
                      onClick={() => router.push('/dashboard/jobs')}
                      className="flex items-center gap-2 py-2.5 px-4 text-white bg-orange-600 hover:bg-orange-700
                        rounded-xl transition-all duration-200 font-medium"
                    >
                      <Briefcase className="w-5 h-5" />
                      <span>Vezi Joburi</span>
                    </button>
                    <div className="w-px h-8 bg-gray-700"></div>
                  </>
                )}

                <button
                  onClick={() => router.push('/notifications')}
                  className="flex items-center gap-2 py-2.5 px-4 text-gray-300 hover:text-white hover:bg-gray-800 
                    rounded-xl transition-all duration-200 font-medium relative"
                >
                  <Bell className="w-5 h-5" />
                  <span>Notificări</span>
                  {unreadCount > 0 && (
                    <span className="absolute -top-1 -right-1 w-5 h-5 bg-red-500 rounded-full text-xs flex items-center justify-center text-white">
                      {unreadCount}
                    </span>
                  )}
                </button>

                <div className="w-px h-8 bg-gray-700"></div>

                {user.role !== 'client' && (
                  <>
                    <button
                      onClick={() => router.push('/profil')}
                      className="flex items-center gap-2 py-2.5 px-4 text-gray-300 hover:text-white hover:bg-gray-800 
                        rounded-xl transition-all duration-200 font-medium"
                    >
                      <User className="w-5 h-5" />
                      <span>Profil</span>
                    </button>

                    <div className="w-px h-8 bg-gray-700"></div>
                  </>
                )}

                <button
                  onClick={handleLogout}
                  className="flex items-center gap-2 py-2.5 px-4 text-gray-300 hover:text-white hover:bg-gray-800 
                    rounded-xl transition-all duration-200 font-medium"
                >
                  <LogIn className="w-5 h-5 rotate-180" />
                  <span>Deconectare</span>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
} 